import {
  SimpleForm as RaSimpleForm,
  useRecordContext,
  useSaveContext
} from 'react-admin';

export const SimpleForm = ({ children, ...props }) => {
  const record = useRecordContext();
  const { save } = useSaveContext();

  const newProps = Object.assign({}, props);
  if (newProps?.transformRecord && record) {
    newProps.record = newProps.transformRecord(Object.assign({}, record));
  }

  if (newProps?.transformSave) {
    newProps.onSubmit = async values => {
      const newValues = await newProps.transformSave(
        Object.assign({}, values),
        record
      );
      return save(newValues);
    };
  }

  return <RaSimpleForm {...newProps}>{children}</RaSimpleForm>;
};
