import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useInput, FieldTitle } from 'ra-core';
import { sanitizeInputRestProps, InputHelperText } from 'react-admin';
import { MuiColorInput } from 'mui-color-input';

export const ColorInput = props => {
  const {
    className,
    defaultValue = '',
    label,
    format,
    helperText,
    onBlur,
    onChange,
    parse,
    resource,
    source,
    validate,
    colorFormat = 'hex',
    fallbackValue = '#000000',
    isAlphaHidden = false,
    ...rest
  } = props;
  const {
    field,
    fieldState: { error, invalid, isTouched },
    formState: { isSubmitted },
    id,
    isRequired
  } = useInput({
    defaultValue,
    format,
    parse,
    resource,
    source,
    type: 'text',
    validate,
    onBlur,
    onChange,
    ...rest
  });
  return (
    <MuiColorInput
      id={id}
      {...field}
      fallbackValue={fallbackValue}
      isAlphaHidden={isAlphaHidden}
      format={colorFormat}
      className={clsx('ra-input', `ra-input-${source}`, className)}
      label={
        label !== '' &&
        label !== false && (
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        )
      }
      error={(isTouched || isSubmitted) && invalid}
      helperText={
        <InputHelperText
          touched={isTouched || isSubmitted}
          error={error?.message}
          helperText={helperText}
        />
      }
      {...sanitizeInputRestProps(rest)}
    />
  );
};

ColorInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.element
  ]),
  fallbackValue: PropTypes.string,
  colorFormat: PropTypes.string,
  isAlphaHidden: PropTypes.bool,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  defaultValue: PropTypes.any,
  format: PropTypes.func,
  parse: PropTypes.func,
  onChange: PropTypes.func,
  validate: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.func)
  ]),
  helperText: PropTypes.string,
  onBlur: PropTypes.func
};

ColorInput.defaultProps = {
  options: {}
};
