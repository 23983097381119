import { Layout as RALayout } from 'react-admin';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AppBar } from './AppBar';
import { Menu } from './Menu';

export const Layout = props => (
  <>
    <RALayout {...props} appBar={AppBar} menu={Menu} />
    {process.env.NODE_ENV !== 'production' && (
      <ReactQueryDevtools initialIsOpen={false} />
    )}
  </>
);
