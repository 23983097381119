import {
  ArrayField,
  ArrayInput,
  ChipField,
  Create,
  Datagrid,
  DateField,
  Edit,
  FormTab,
  List,
  ReferenceInput,
  Resource,
  SelectInput,
  Show,
  SimpleFormIterator,
  SimpleFormIteratorItemContext,
  SimpleShowLayout,
  SingleFieldList,
  TabbedForm,
  TextField,
  TextInput,
  useListContext
} from 'react-admin';
import { CodeEditor } from '../components/CodeEditor';
import { FilterSidebar } from '../components/FilterSidebar';
import { HiddenInput } from '../components/HiddenInput';
import { RecordTitle } from '../components/RecordTitle';
import { SimpleFormIteratorInput } from '../components/SimpleFormIteratorInput';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import gql from 'graphql-tag';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Typography from '@mui/material/Typography';

export const name = 'AiPrompt';
export const fragments = {
  type: 'document',
  mode: 'replace',
  doc: gql`
    fragment FragAiPrompts on aiPrompts {
      id
      name
      createdAt
      updatedAt
      defaultEngineId
      defaultEngine {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      _count {
        aiPromptLog
        aiPromptToAiEngines
        aiPromptVariables
        __typename
      }
      aiPromptToAiEngines {
        id
        aiPromptId
        aiEngineId
        prompt
        createdAt
        updatedAt
        aiEngine {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      aiPromptVariables(orderBy: { sequence: asc }) {
        id
        aiPromptId
        name
        script
        sequence
        type
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  `
};

const list = () => (
  <List
    title="AI Prompts"
    aside={<FilterSidebar />}
    sort={{ field: 'name', order: 'ASC' }}
  >
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="defaultEngine.id" name="Default Engine" />
      <ArrayField source="aiPromptToAiEngines">
        <SingleFieldList>
          <ChipField source="aiEngineId" />
        </SingleFieldList>
      </ArrayField>
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);

const ShowEnginePrompts = () => {
  const { data, isLoading } = useListContext();
  if (isLoading) return null;

  return (
    <>
      {data.map(record => (
        <Accordion key={record.aiEngineId}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${record.aiEngineId}-content`}
            id={`${record.aiEngineId}-header`}
          >
            <Typography>{record.aiEngineId}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CodeEditor
              defaultValue={record.prompt}
              source="prompt"
              options={{
                domReadOnly: true,
                readOnly: true
              }}
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

const ShowVariableScripts = () => {
  const { data, isLoading } = useListContext();
  if (isLoading) return null;

  return (
    <>
      {data.map(record => (
        <Accordion key={record.aiEngineId}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${record.id}-content`}
            id={`${record.id}-header`}
          >
            <Typography>
              {record.name} ({record.type})
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CodeEditor
              defaultValue={record.script}
              source="script"
              options={{
                domReadOnly: true,
                readOnly: true
              }}
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

const show = () => (
  <Show title={<RecordTitle source="name" prefix="AI Prompt" />}>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="id" />
      <TextField source="defaultEngine.id" name="Default Engine" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <Grid container spacing={1}>
        <Grid xs={12} md={6}>
          <Typography variant="h6">Variables</Typography>
          <ArrayField source="aiPromptVariables" order="sequence">
            <ShowVariableScripts />
          </ArrayField>
        </Grid>
        <Grid xs={12} md={6}>
          <Typography variant="h6">Engines</Typography>
          <ArrayField source="aiPromptToAiEngines">
            <ShowEnginePrompts />
          </ArrayField>
        </Grid>
      </Grid>
    </SimpleShowLayout>
  </Show>
);

const edit = () => (
  <Edit
    mutationMode="pessimistic"
    title={<RecordTitle source="name" prefix="Edit AI Prompt" />}
  >
    <TabbedForm>
      <FormTab label="Prompt">
        <TextInput source="id" fullWidth />
        <TextInput source="name" fullWidth />
        <ReferenceInput source="defaultEngine_id" reference="AiEngine">
          <SelectInput optionText="name" fullWidth />
        </ReferenceInput>
      </FormTab>
      <FormTab label="Variables">
        <ArrayInput source="aiPromptVariables" reference="aiPromptVariables">
          <SimpleFormIteratorInput
            fullWidth
            title="Variables"
            orderSource="sequence"
          >
            <TextInput source="name" fullWidth />
            <SelectInput
              source="type"
              choices={[
                { id: 'sql', name: 'sql' },
                { id: 'httpQuery', name: 'httpQuery' }
              ]}
              defaultValue={'sql'}
              fullWidth
            />
            <CodeEditor source="script" language="handlebars" />
          </SimpleFormIteratorInput>
        </ArrayInput>
      </FormTab>
      <FormTab label="Engines">
        <ArrayInput source="aiPromptToAiEngines" fullWidth>
          <SimpleFormIterator fullWidth title="Engines">
            <ReferenceInput source="aiEngine.id" reference="AiEngine">
              <SelectInput optionText="name" fullWidth />
            </ReferenceInput>
            <CodeEditor source="prompt" language="handlebars" />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Edit>
);

const create = () => (
  <Create title="Create AI Prompt">
    <TabbedForm>
      <FormTab label="Prompt">
        <TextInput source="id" fullWidth />
        <TextInput source="name" fullWidth />
        <ReferenceInput source="defaultEngine_id" reference="AiEngine">
          <SelectInput optionText="name" fullWidth />
        </ReferenceInput>
      </FormTab>
      <FormTab label="Variables">
        <ArrayInput source="aiPromptVariables" fullWidth>
          <SimpleFormIterator fullWidth>
            <SimpleFormIteratorItemContext.Consumer>
              {({ index }) => <HiddenInput source="sequence" value={index} />}
            </SimpleFormIteratorItemContext.Consumer>
            <TextInput source="name" fullWidth />
            <SelectInput
              source="type"
              choices={[
                { id: 'sql', name: 'sql' },
                { id: 'httpQuery', name: 'httpQuery' }
              ]}
              defaultValue={'sql'}
              fullWidth
            />
            <CodeEditor source="script" language="handlebars" />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="Engines">
        <ArrayInput source="aiPromptToAiEngines" fullWidth>
          <SimpleFormIterator fullWidth>
            <ReferenceInput source="aiEngine.id" reference="AiEngine">
              <SelectInput optionText="name" fullWidth />
            </ReferenceInput>
            <CodeEditor source="prompt" language="handlebars" />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Create>
);

export default function AdminResource(props) {
  return (
    <Resource
      create={create}
      edit={edit}
      list={list}
      name={name}
      show={show}
      {...props}
    />
  );
}
