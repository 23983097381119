import { Datagrid, List, Resource, TextField } from 'react-admin';
import gql from 'graphql-tag';

export const name = 'BusinessMetricWorkoutCategory';

export const fragments = {
  type: 'document',
  doc: gql`
    fragment FragBusinessMetricWorkoutCategories on businessMetricWorkoutCategories {
      id
      value
      children {
        id
        value
        __typename
      }
      __typename
    }
  `
};

const list = () => (
  <List>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="value" />
    </Datagrid>
  </List>
);

export default function AdminResource(props) {
  return <Resource list={list} name={name} {...props} />;
}
