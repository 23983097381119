import {
  Create,
  Datagrid,
  DateField,
  Edit,
  List,
  NumberField,
  NumberInput,
  Resource,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput
} from 'react-admin';
import { ColorField } from 'react-admin-color-picker';
import { ColorInput } from '../components/ColorInput';
import { FilterSidebar } from '../components/FilterSidebar';
import gql from 'graphql-tag';

export const name = 'WorkoutLevel';
export const fragments = {
  type: 'document',
  doc: gql`
    fragment FragWorkoutLevels on workoutLevels {
      id
      color
      description
      name
      order
      createdAt
      updatedAt
      _count {
        exercises
        prorgrams
        users
        userProfiles
        workouts
        __typename
      }
      __typename
    }
  `
};

const list = () => (
  <List aside={<FilterSidebar />} sort={{ field: 'order', order: 'ASC' }}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="description" />
      <ColorField source="color" />
      <NumberField source="order" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);

const show = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="description" />
      <ColorField source="color" />
      <NumberField source="order" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </SimpleShowLayout>
  </Show>
);

const edit = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm redirect="show">
      <TextInput source="name" />
      <TextInput source="description" multiline fullWidth />
      <ColorInput source="color" />
      <NumberInput source="order" />
    </SimpleForm>
  </Edit>
);

const create = () => (
  <Create>
    <SimpleForm redirect="list">
      <TextInput source="name" />
      <TextInput source="description" multiline fullWidth />
      <ColorInput source="color" />
      <NumberInput source="order" />
    </SimpleForm>
  </Create>
);

export default function AdminResource(props) {
  return (
    <Resource
      create={create}
      edit={edit}
      list={list}
      name={name}
      show={show}
      {...props}
    />
  );
}
