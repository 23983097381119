import Backdrop from '@mui/material/Backdrop';
import Icon from '@mui/material/Icon';
import LinearProgress from '@mui/material/LinearProgress';
import { ReactComponent as LogoTextAlt } from '../assets/logoTextAlt.svg';

export const SplashLoader = () => {
  return (
    <Backdrop
      sx={{
        backgroundColor: '#000',
        zIndex: theme => theme.zIndex.drawer + 1,
        flexDirection: 'column'
      }}
      open={true}
    >
      <Icon
        component={LogoTextAlt}
        sx={{
          height: 'auto',
          width: '50%',
          alignSelf: 'center'
        }}
      />
      <LinearProgress
        color="error"
        sx={{ width: '30%', alignSelf: 'center' }}
      />
    </Backdrop>
  );
};
