export const WORKOUT_TYPES = {
  STUDIO: {
    label: 'Studio',
    value: 'STUDIO'
  },
  OPEN_GYM: {
    label: 'Open Gym',
    value: 'OPEN_GYM'
  },
  FREESTYLE: {
    label: 'Freestyle',
    value: 'FREESTYLE'
  }
};
export const FILE_TYPES = {
  AUDIO: 'AUDIO',
  VIDEO: 'VIDEO'
};
