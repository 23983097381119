import * as React from 'react';
import {
  Children,
  cloneElement,
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react';
import { styled } from '@mui/material';
import clsx from 'clsx';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { FormDataConsumer, useRecordContext, useTranslate } from 'ra-core';
import {
  AddItemButton as DefaultAddItemButton,
  Confirm,
  RemoveItemButton as DefaultRemoveItemButton,
  ReOrderButtons as DefaultReOrderButtons,
  SimpleFormIteratorClasses,
  SimpleFormIteratorContext,
  SimpleFormIteratorItem,
  SimpleFormIteratorPrefix,
  useArrayInput
} from 'ra-ui-materialui';
import HiddenInput from './HiddenInput';
import { ClearArrayButton } from 'ra-ui-materialui/dist/esm/input/ArrayInput/ClearArrayButton';

export const SimpleFormIteratorInput = props => {
  const {
    addButton = <DefaultAddItemButton />,
    removeButton = <DefaultRemoveItemButton />,
    reOrderButtons = <DefaultReOrderButtons />,
    children,
    className,
    resource,
    source,
    disabled,
    disableAdd,
    disableClear,
    disableRemove,
    disableReordering,
    inline,
    getItemLabel = false,
    fullWidth,
    sx,
    orderSource
  } = props;
  const [confirmIsOpen, setConfirmIsOpen] = useState(false);
  const { append, fields, move, remove, replace } = useArrayInput(props);
  const translate = useTranslate();
  const record = useRecordContext(props);
  const initialDefaultValue = useRef({});

  const removeField = useCallback(
    index => {
      remove(index);
    },
    [remove]
  );

  if (fields.length > 0) {
    // eslint-disable-next-line no-unused-vars
    const { id, ...rest } = fields[0];
    initialDefaultValue.current = rest;
    for (const k in initialDefaultValue.current)
      initialDefaultValue.current[k] = null;
  }

  const addField = useCallback(
    (item = undefined) => {
      let defaultValue = item;
      if (item == null) {
        defaultValue = initialDefaultValue.current;
        if (
          Children.count(children) === 1 &&
          React.isValidElement(Children.only(children)) &&
          !Children.only(children).props.source
        ) {
          defaultValue = '';
        } else {
          defaultValue = defaultValue || {};
          Children.forEach(children, input => {
            if (
              React.isValidElement(input) &&
              input.type !== FormDataConsumer &&
              input.props.source
            ) {
              defaultValue[input.props.source] =
                input.props.defaultValue ?? null;
            }
          });
        }
      }
      append(defaultValue);
    },
    [append, children]
  );

  const handleAddButtonClick = originalOnClickHandler => event => {
    addField();
    if (originalOnClickHandler) {
      originalOnClickHandler(event);
    }
  };

  const handleReorder = useCallback(
    (origin, destination) => {
      move(origin, destination);
    },
    [move]
  );

  const handleArrayClear = useCallback(() => {
    replace([]);
    setConfirmIsOpen(false);
  }, [replace]);

  const records = get(record, source);

  const context = useMemo(
    () => ({
      total: fields.length,
      add: addField,
      remove: removeField,
      reOrder: handleReorder,
      source
    }),
    [addField, fields.length, handleReorder, removeField, source]
  );
  return fields ? (
    <SimpleFormIteratorContext.Provider value={context}>
      <Root
        className={clsx(
          className,
          fullWidth && 'fullwidth',
          disabled && 'disabled'
        )}
        sx={sx}
      >
        <ul className={SimpleFormIteratorClasses.list}>
          {fields.map((member, index) => (
            <SimpleFormIteratorItem
              key={member.id}
              disabled={disabled}
              disableRemove={disableRemove}
              disableReordering={disableReordering}
              fields={fields}
              getItemLabel={getItemLabel}
              index={index}
              member={`${source}.${index}`}
              onRemoveField={removeField}
              onReorder={handleReorder}
              record={(records && records[index]) || {}}
              removeButton={removeButton}
              reOrderButtons={reOrderButtons}
              resource={resource}
              source={source}
              inline={inline}
            >
              <HiddenInput source={orderSource} defaultValue={index + 1} />
              {children}
            </SimpleFormIteratorItem>
          ))}
        </ul>
        {!disabled && !(disableAdd && (disableClear || disableRemove)) && (
          <div className={SimpleFormIteratorClasses.buttons}>
            {!disableAdd && (
              <div className={SimpleFormIteratorClasses.add}>
                {cloneElement(addButton, {
                  className: clsx('button-add', `button-add-${source}`),
                  onClick: handleAddButtonClick(addButton.props.onClick)
                })}
              </div>
            )}
            {fields.length > 0 && !disableClear && !disableRemove && (
              <div className={SimpleFormIteratorClasses.clear}>
                <Confirm
                  isOpen={confirmIsOpen}
                  title={translate('ra.action.clear_array_input')}
                  content={translate('ra.message.clear_array_input')}
                  onConfirm={handleArrayClear}
                  onClose={() => setConfirmIsOpen(false)}
                />
                <ClearArrayButton onClick={() => setConfirmIsOpen(true)} />
              </div>
            )}
          </div>
        )}
      </Root>
    </SimpleFormIteratorContext.Provider>
  ) : null;
};

SimpleFormIteratorInput.defaultProps = {
  disableAdd: false,
  disableRemove: false,
  addButton: <DefaultAddItemButton />,
  removeButton: <DefaultRemoveItemButton />,
  reOrderButtons: <DefaultReOrderButtons />,
  getItemLabel: false
};

SimpleFormIteratorInput.propTypes = {
  addButton: PropTypes.element,
  removeButton: PropTypes.element,
  children: PropTypes.node,
  className: PropTypes.string,
  field: PropTypes.object,
  fields: PropTypes.array,
  fieldState: PropTypes.object,
  formState: PropTypes.object,
  fullWidth: PropTypes.bool,
  inline: PropTypes.bool,
  orderSource: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string,
  resource: PropTypes.string,
  translate: PropTypes.func,
  disableAdd: PropTypes.bool,
  disableRemove: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  TransitionProps: PropTypes.shape({}),
  reOrderButtons: PropTypes.element,
  disableReordering: PropTypes.bool,
  disableClear: PropTypes.bool,
  getItemLabel: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  sx: PropTypes.object,
  disabled: PropTypes.bool
};

const Root = styled('div', {
  name: SimpleFormIteratorPrefix,
  overridesResolver: (_props, styles) => styles.root
})(({ theme }) => ({
  '& > ul': {
    padding: 0,
    marginTop: 0,
    marginBottom: 0
  },
  '& > ul > li:last-child': {
    borderBottom: 'none'
  },
  [`& .${SimpleFormIteratorClasses.line}`]: {
    display: 'flex',
    listStyleType: 'none',
    borderBottom: `solid 1px ${theme.palette.divider}`,
    [theme.breakpoints.down('sm')]: { display: 'block' }
  },
  [`& .${SimpleFormIteratorClasses.index}`]: {
    display: 'flex',
    alignItems: 'top',
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('md')]: { display: 'none' }
  },
  [`& .${SimpleFormIteratorClasses.form}`]: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column'
  },
  [`&.fullwidth > ul > li > .${SimpleFormIteratorClasses.form}`]: {
    flex: 2
  },
  [`& .${SimpleFormIteratorClasses.inline}`]: {
    flexDirection: 'row',
    columnGap: '1em',
    flexWrap: 'wrap'
  },
  [`& .${SimpleFormIteratorClasses.action}`]: {
    marginTop: theme.spacing(0.5),
    visibility: 'hidden',
    '@media(hover:none)': {
      visibility: 'visible'
    }
  },
  [`& .${SimpleFormIteratorClasses.buttons}`]: {
    display: 'flex'
  },
  [`& .${SimpleFormIteratorClasses.add}`]: {
    borderBottom: 'none'
  },
  [`& .${SimpleFormIteratorClasses.clear}`]: {
    borderBottom: 'none'
  },
  [`& .${SimpleFormIteratorClasses.line}:hover > .${SimpleFormIteratorClasses.action}`]:
    {
      visibility: 'visible'
    }
}));
