import { memo } from 'react';
import { UrlField } from 'react-admin';

import PropTypes from 'prop-types';

export const AudioField = memo(props => {
  const { source } = props;
  return <UrlField source={source} />;
});

AudioField.propTypes = {
  source: PropTypes.string.isRequired
};

AudioField.displayName = 'VideoField';
