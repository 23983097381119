import {
  ArrayField,
  CheckboxGroupInput,
  ChipField,
  Create,
  DateField,
  Edit,
  FileField,
  FileInput,
  ImageField,
  List,
  RadioButtonGroupInput,
  ReferenceArrayInput,
  Resource,
  Show,
  SingleFieldList,
  TextField,
  TextInput
} from 'react-admin';
import { EquipmentFilterSidebar } from '../components/FilterSidebar';
import { AudioField } from '../components/AudioField';
import { Datagrid } from '../components/DataGrid';
import { ImageInput } from '../components/ImageInput';
import { ShowLayout } from '../components/ShowLayout';
import { SimpleForm } from '../components/SimpleForm';
import { VideoField } from '../components/VideoField';
import { getFileNameFromRaw } from '../helpers/files';
import gql from 'graphql-tag';

export const name = 'Exercise';
export const fragments = {
  type: 'document',
  doc: gql`
    fragment FragExercise on findManyExercise {
      id
      name
      setup
      createdAt
      updatedAt
      _count {
        accessories
        devices
        equipments
        goals
        instructors
        levels
        muscleGroups
        __typename
      }
      accessories {
        id
        name
        __typename
      }
      devices {
        id
        name
        __typename
      }
      equipments {
        id
        name
        __typename
      }
      goals {
        id
        name
        __typename
      }
      instructors {
        id
        name
        __typename
      }
      levels {
        id
        name
        __typename
      }
      media {
        id
        type
        fileName
        isDefault
        url
        __typename
      }
      muscleGroups {
        id
        name
        __typename
      }
      __typename
    }
  `
};

const recordTransformer = record => {
  record?.media?.reduce((acc, media) => {
    if (!media.isDefault) return acc;

    media.title = media.fileName;
    media.src = media.url;

    const type = media.type.toLowerCase();
    record[type] = media;
    acc[type] = media;

    return acc;
  }, {});
  return record;
};

const recordSaveTransformer = record => {
  const newRecord = Object.assign({}, record, { media: [] });

  Object.keys(newRecord).map(key => {
    if (['audio', 'image', 'video'].includes(key)) {
      const type = key.toUpperCase();
      if (record[key] && typeof record[key] === 'object') {
        if (record[key]?.rawFile) {
          newRecord.media.push({
            fileName: getFileNameFromRaw(record[key].rawFile),
            isDefault: true,
            url: record[key],
            state: key === 'image' ? 'COMPLETE' : 'PROCESSING',
            type,
            __typename: 'Media'
          });
        } else {
          newRecord.media.push({ ...record[key] });
        }
      }
      delete newRecord[key];
    }
  });

  return newRecord;
};

const list = () => (
  <List
    aside={<EquipmentFilterSidebar />}
    sort={{ field: 'name', order: 'ASC' }}
  >
    <Datagrid rowClick="show" transformRecord={recordTransformer}>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="setup" />
      <AudioField label="Audio" title="audio.fileName" source="audio.url" />
      <ImageField label="Image" title="image.fileName" source="image.url" />
      <VideoField label="Video" title="video.fileName" source="video.url" />
      <ArrayField label="Accessories" source="accessories">
        <SingleFieldList resource="Accessory">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField label="Devices" source="devices">
        <SingleFieldList resource="Device">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField label="Equipments" source="equipments">
        <SingleFieldList resource="Equipment">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField label="Goals" source="goals">
        <SingleFieldList resource="Goal">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="instructors">
        <SingleFieldList resource="Instructor">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField label="Muscle Groups" source="muscleGroups">
        <SingleFieldList resource="MuscleGroup">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="levels" sort={{ field: 'order', order: 'ASC' }}>
        <SingleFieldList resource="WorkoutLevel">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);

const show = () => (
  <Show>
    <ShowLayout transformRecord={recordTransformer}>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="setup" />
      <AudioField label="Audio" title="audio.fileName" source="audio.url" />
      <ImageField label="Image" title="image.fileName" source="image.url" />
      <VideoField label="Video" title="video.fileName" source="video.url" />
      <ArrayField label="Accessories" source="accessories">
        <SingleFieldList resource="Accessory">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField label="Devices" source="devices">
        <SingleFieldList resource="Device">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField label="Equipments" source="equipments">
        <SingleFieldList resource="Equipment">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField label="Goals" source="goals">
        <SingleFieldList resource="Goal">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="instructors">
        <SingleFieldList resource="Instructor">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField label="Muscle Groups" source="muscleGroups">
        <SingleFieldList resource="MuscleGroup">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="levels" sort={{ field: 'order', order: 'ASC' }}>
        <SingleFieldList resource="WorkoutLevel">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </ShowLayout>
  </Show>
);

const edit = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm
      transformRecord={recordTransformer}
      transformSave={recordSaveTransformer}
    >
      <TextField source="id" />
      <TextInput source="name" />
      <RadioButtonGroupInput
        source="setup"
        choices={[
          { id: 'default', name: 'Default' },
          { id: 'topline', name: 'Topline' },
          { id: 'midline', name: 'Midline' },
          { id: 'bottomline', name: 'Bottomline' }
        ]}
      />
      <FileInput
        source="audio"
        accept="audio/*"
        label="Audio File"
        multiple={false}
      >
        <FileField source="src" title="title" />
      </FileInput>
      <ImageInput
        source="image"
        accept="image/*"
        label="Image File"
        multiple={false}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <FileInput
        source="video"
        accept="video/*"
        label="Video File"
        multiple={false}
      >
        <FileField source="src" title="title" />
      </FileInput>
      <ReferenceArrayInput source="accessories_ids" reference="Accessory">
        <CheckboxGroupInput optionValue="id" optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="devices_ids" reference="Device">
        <CheckboxGroupInput optionValue="id" optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="equipments_ids" reference="Equipment">
        <CheckboxGroupInput optionValue="id" optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="goals_ids" reference="Goal">
        <CheckboxGroupInput optionValue="id" optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="instructors_ids" reference="Instructor">
        <CheckboxGroupInput optionValue="id" optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="muscleGroups_ids" reference="MuscleGroup">
        <CheckboxGroupInput optionValue="id" optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        source="levels"
        reference="WorkoutLevel"
        sort={{ field: 'order', order: 'ASC' }}
      >
        <CheckboxGroupInput optionValue="id" optionText="name" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
);

const create = () => (
  <Create>
    <SimpleForm transformSave={recordSaveTransformer}>
      <TextField source="id" />
      <TextInput source="name" />
      <RadioButtonGroupInput
        source="setup"
        choices={[
          { id: 'default', name: 'Default' },
          { id: 'topline', name: 'Topline' },
          { id: 'midline', name: 'Midline' },
          { id: 'bottomline', name: 'Bottomline' }
        ]}
      />
      <FileInput
        source="audio"
        accept="audio/*"
        label="Audio File"
        multiple={false}
      >
        <FileField source="src" title="title" />
      </FileInput>
      <ImageInput
        source="image"
        accept="image/*"
        label="Image File"
        multiple={false}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <FileInput
        source="video"
        accept="video/*"
        label="Video File"
        multiple={false}
      >
        <FileField source="src" title="title" />
      </FileInput>
      <ReferenceArrayInput source="accessories_ids" reference="Accessory">
        <CheckboxGroupInput
          optionValue="id"
          optionText="name"
          defaultValue={[]}
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="devices_ids" reference="Device">
        <CheckboxGroupInput
          optionValue="id"
          optionText="name"
          defaultValue={[]}
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="equipments_ids" reference="Equipment">
        <CheckboxGroupInput
          optionValue="id"
          optionText="name"
          defaultValue={[]}
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="goals_ids" reference="Goal">
        <CheckboxGroupInput
          optionValue="id"
          optionText="name"
          defaultValue={[]}
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="instructors_ids" reference="Instructor">
        <CheckboxGroupInput
          optionValue="id"
          optionText="name"
          defaultValue={[]}
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        source="levels_ids"
        reference="WorkoutLevel"
        sort={{ field: 'order', order: 'ASC' }}
      >
        <CheckboxGroupInput
          optionValue="id"
          optionText="name"
          defaultValue={[]}
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="muscleGroups_ids" reference="MuscleGroup">
        <CheckboxGroupInput
          optionValue="id"
          optionText="name"
          defaultValue={[]}
        />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);

export default function AdminResource(props) {
  return (
    <Resource
      create={create}
      edit={edit}
      list={list}
      name={name}
      show={show}
      {...props}
    />
  );
}
