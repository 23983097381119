/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import { useState } from 'react';
import {
  DateInput,
  SimpleForm,
  SelectInput,
  useGetList,
  useTheme
} from 'react-admin';
import { BarGraph } from './BarGraph';
import { CalendarGraph } from './CalendarGraph';
import { SunburstGraph } from './SunburstGraph';
import { scaleQuantile } from 'd3-scale';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { CircularProgress, Typography } from '@mui/material';

export const BusinessAnalytics = () => {
  const theme = useTheme();
  const isDarkMode = (theme?.[0]?.palette?.mode || theme?.[0]) === 'dark';
  const [start, setStart] = useState(new Date('2022-01-01').getTime());
  const [end, setEnd] = useState(new Date('2023-12-31').getTime());
  const [businessId, setBusinessId] = useState('');

  const timePeriod = React.useMemo(() => {
    const startDate = new Date(start);
    const endDate = new Date(end);

    // if difference is greater than 1 year return 'monthly', if greater than 3 month return 'weekly', else return 'daily'
    const difference = (endDate - startDate) / (1000 * 3600 * 24);
    if (difference > 365) return 'monthly';
    if (difference > 90) return 'weekly';
    return 'daily';
  }, [start, end]);
  const timePeriodLabel =
    timePeriod.charAt(0).toUpperCase() + timePeriod.slice(1);

  const getColor = (value, maxValue) => {
    if (value === 0) return 'red';
    const percentage = value / maxValue;
    const green = Math.round(percentage * 255);
    return `rgb(${255 - green}, ${green}, 0)`;
  };

  const {
    data: businessData,
    isLoading,
    isFetched
  } = useGetList('Business', {});

  const businesses = React.useMemo(
    () =>
      Object.values(businessData || {}).map(business => ({
        id: business.id,
        name: business.name
      })),
    [businessData]
  );

  const {
    data: signUpSummaries,
    isLoading: isSignUpsLoading,
    isFetched: isSignUpsFetched
  } = useGetList(
    'BusinessMetricUserSummary',
    {
      filter: {
        startDate: start,
        endDate: end,
        businessId: businessId
      }
    },
    {
      enabled: isFetched && !isLoading
    }
  );

  const signUpSummaryChartData = React.useMemo(() => {
    if (!signUpSummaries) {
      return {
        bar: [],
        users: [],
        maxUsers: 0,
        minUsers: 0,
        totalUsers: 0
      };
    }
    const data = {
      bar: [],
      users: [],
      maxUsers: 0,
      minUsers: -1,
      totalUsers: 0
    };

    const formatted = signUpSummaries.reduce((acc, curr) => {
      data.totalUsers += curr.users;

      if (curr.users > data.maxUsers) {
        data.maxUsers = curr.users;
      }
      if (curr.users < data.minUsers || data.minUsers === -1) {
        data.minUsers = curr.users;
      }

      const date = new Date(curr.date);
      if (timePeriod === 'monthly') {
        date.setUTCDate(1);
      } else if (timePeriod === 'weekly') {
        date.setUTCDate(date.getUTCDate() - date.getUTCDay());
      }

      const dateKey = date.toISOString().split('T')[0];
      if (acc[dateKey]) {
        acc[dateKey].users += curr.users;
      } else {
        acc[dateKey] = {
          users: curr.users
        };
      }
      return acc;
    }, {});

    for (let key in formatted) {
      if (formatted[key].users > 0) {
        data.bar.push({
          users: formatted[key].users,
          date: key
        });
        data.users.push({
          x: key,
          y: formatted[key].users
        });
      }
    }

    return data;
  }, [isSignUpsLoading, signUpSummaries, timePeriod]);

  const {
    data: workoutSummaries,
    isLoading: isWorkoutsLoading,
    isFetched: isWorkoutsFetched
  } = useGetList(
    'BusinessMetricWorkoutSummary',
    {
      filter: {
        startDate: start,
        endDate: end,
        businessId: businessId
      }
    },
    {
      enabled: isFetched && !isLoading
    }
  );

  const workoutSummaryChartData = React.useMemo(() => {
    if (!workoutSummaries) {
      return {
        bar: [],
        minutes: [],
        workouts: [],
        maxMinutes: 0,
        minMinutes: 0,
        totalMinutes: 0,
        maxWorkouts: 0,
        minWorkouts: 0,
        totalWorkouts: 0
      };
    }
    const data = {
      bar: [],
      minutes: [],
      workouts: [],
      maxMinutes: 0,
      minMinutes: -1,
      totalMinutes: 0,
      maxWorkouts: 0,
      minWorkouts: -1,
      totalWorkouts: 0
    };

    const formatted = workoutSummaries.reduce((acc, curr) => {
      data.totalMinutes += curr.minutes;
      data.totalWorkouts += curr.workouts;
      if (curr.minutes > data.maxMinutes) {
        data.maxMinutes = curr.minutes;
      }
      if (curr.minutes < data.minMinutes || data.minMinutes === -1) {
        data.minMinutes = curr.minutes;
      }
      if (curr.workouts > data.maxWorkouts) {
        data.maxWorkouts = curr.workouts;
      }
      if (curr.workouts < data.minWorkouts || data.minWorkouts === -1) {
        data.minWorkouts = curr.workouts;
      }

      const date = new Date(curr.date);
      if (timePeriod === 'monthly') {
        date.setUTCDate(1);
      } else if (timePeriod === 'weekly') {
        date.setUTCDate(date.getUTCDate() - date.getUTCDay());
      }
      const dateKey = date.toISOString().split('T')[0];
      if (acc[dateKey]) {
        acc[dateKey].minutes += curr.minutes;
        acc[dateKey].workouts += curr.workouts;
      } else {
        acc[dateKey] = {
          minutes: curr.minutes,
          workouts: curr.workouts
        };
      }
      return acc;
    }, {});

    for (let key in formatted) {
      if (formatted[key].minutes > 0 || formatted[key].workouts > 0) {
        data.bar.push({
          minutes: formatted[key].minutes,
          workouts: formatted[key].workouts,
          date: key
        });
      }
      if (formatted[key].minutes > 0) {
        data.minutes.push({
          x: key,
          y: formatted[key].minutes
        });
      }
      if (formatted[key].workouts > 0) {
        data.workouts.push({
          x: key,
          y: formatted[key].workouts
        });
      }
    }

    return data;
  }, [workoutSummaries, timePeriod, isWorkoutsLoading]);

  const {
    data: categoryGraphData,
    isLoading: isCategoryLoading,
    isFetched: isCategoryFetched
  } = useGetList(
    'BusinessMetricWorkoutCategory',
    {
      filter: {
        startDate: start,
        endDate: end,
        businessId
      }
    },
    {
      enabled: isFetched && !isLoading
    }
  );

  const categoryGraphDataFiltered = React.useMemo(() => {
    if (!categoryGraphData) {
      return [];
    }
    return categoryGraphData.filter(d => d.value > 0);
  }, [categoryGraphData]);

  const getWorkoutSummaryColorScale = ({ value, id }) => {
    const fn = scaleQuantile()
      .domain(workoutSummaryChartData.bar.map(d => d[id]))
      .range([
        '#d73027',
        '#f46d43',
        '#fdae61',
        '#fee08b',
        '#ffffbf',
        '#d9ef8b',
        '#a6d96a',
        '#66bd63',
        '#1a9850'
      ]);
    return fn(value);
  };
  const signupColorScale = ({ value, id }) => {
    const fn = scaleQuantile()
      .domain(signUpSummaryChartData.bar.map(d => d[id]))
      .range([
        '#d73027',
        '#f46d43',
        '#fdae61',
        '#fee08b',
        '#ffffbf',
        '#d9ef8b',
        '#a6d96a',
        '#66bd63',
        '#1a9850'
      ]);
    return fn(value);
  };

  return (
    <div>
      <SimpleForm toolbar={false}>
        <DateInput
          source="startDate" // Name of the field
          label="Start Date"
          defaultValue={start}
          onChange={e => setStart(e.target.value)}
        />
        <DateInput
          source="endDate" // Name of the field
          label="End Date"
          defaultValue={end}
          onChange={e => setEnd(e.target.value)}
        />
        <SelectInput
          source="business"
          placeholder="All Data"
          defaultValue={businessId || ''}
          emptyText={'All Data'}
          emptyValue={''}
          value={businessId}
          choices={businesses?.map(business => ({
            id: business.id,
            name: business.name
          }))}
          field={{ name: 'business', type: 'select' }}
          onChange={e => {
            setBusinessId(e.target.value || '');
          }}
        />
      </SimpleForm>

      <Box sx={{ flexGrow: 1, marginBottom: 33 }}>
        <Grid container spacing={4} rowSpacing={15} width={'100%'}>
          <Grid item xs={12} md={6} height={650}>
            <Box width="100%" height="100%">
              {isWorkoutsLoading || !isWorkoutsFetched ? (
                <CircularProgress />
              ) : (
                <>
                  <Typography variant="h5">
                    {timePeriodLabel} Minutes
                  </Typography>
                  {workoutSummaryChartData?.bar?.length ? (
                    <BarGraph
                      data={
                        workoutSummaryChartData.bar.filter(
                          b => b.minutes > 0
                        ) || []
                      }
                      indexBy="date"
                      keys={['minutes']}
                      colors={getWorkoutSummaryColorScale}
                      axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 45,
                        legendOffset: 70,
                        legendPosition: 'middle',
                        legend:
                          'Total Minutes: ' +
                          Intl.NumberFormat(navigator.language).format(
                            workoutSummaryChartData.totalMinutes
                          )
                      }}
                      axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendOffset: -55,
                        legend: 'Minutes',
                        legendPosition: 'middle'
                      }}
                    />
                  ) : (
                    <Typography variant="h6">
                      No data for this time period
                    </Typography>
                  )}
                </>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={6} height={650}>
            <Box width="100%" height="100%">
              {isWorkoutsLoading || !isWorkoutsFetched ? (
                <CircularProgress />
              ) : (
                <>
                  <Typography variant="h5">
                    {timePeriodLabel} Workouts
                  </Typography>
                  {workoutSummaryChartData?.bar?.length ? (
                    <BarGraph
                      data={
                        workoutSummaryChartData.bar.filter(
                          b => b.workouts > 0
                        ) || []
                      }
                      indexBy="date"
                      keys={['workouts']}
                      colors={getWorkoutSummaryColorScale}
                      axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 45,
                        legendOffset: 70,
                        legendPosition: 'middle',
                        legend:
                          'Total Workouts: ' +
                          Intl.NumberFormat(navigator.language).format(
                            workoutSummaryChartData.totalWorkouts
                          )
                      }}
                      axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendOffset: -50,
                        legend: 'Workouts',
                        legendPosition: 'middle'
                      }}
                    />
                  ) : (
                    <Typography variant="h6">
                      No data for this time period
                    </Typography>
                  )}
                </>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={6} height={650}>
            <Box width="100%" height="100%">
              {isSignUpsLoading || !isSignUpsFetched ? (
                <CircularProgress />
              ) : (
                <>
                  <Typography variant="h5">
                    {timePeriodLabel} Signups
                  </Typography>
                  {signUpSummaryChartData?.bar?.length ? (
                    <BarGraph
                      data={signUpSummaryChartData.bar || []}
                      indexBy="date"
                      keys={['users']}
                      colors={signupColorScale}
                      axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 45,
                        legendOffset: 70,
                        legendPosition: 'middle',
                        legend:
                          'Total Signups: ' +
                          Intl.NumberFormat(navigator.language).format(
                            signUpSummaryChartData.totalUsers
                          )
                      }}
                      axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendOffset: -50,
                        legend: 'Signups',
                        legendPosition: 'middle'
                      }}
                    />
                  ) : (
                    <Typography variant="h6">
                      No data for this time period
                    </Typography>
                  )}
                </>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={6} height={650}>
            <Box width="100%" height="100%">
              {isCategoryLoading || !isCategoryFetched ? (
                <CircularProgress />
              ) : (
                <>
                  <Typography variant="h5">Categories</Typography>
                  {categoryGraphDataFiltered?.length ? (
                    <SunburstGraph
                      width={750}
                      height={600}
                      data={{
                        id: 'workoutCategories',
                        color: 'hsl(0, 0%, 0%)',
                        children: categoryGraphDataFiltered || []
                      }}
                      id={'value'}
                      name={'id'}
                      margin={{ top: 100, right: 100, bottom: 140, left: 100 }}
                    />
                  ) : (
                    <Typography variant="h6">
                      No data for this time period
                    </Typography>
                  )}
                </>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={6} height={650}>
            <Box width="100%" height="100%">
              {isWorkoutsLoading || !isWorkoutsFetched ? (
                <CircularProgress />
              ) : (
                <>
                  <Typography variant="h5">Workout Density</Typography>
                  {workoutSummaries?.length ? (
                    <CalendarGraph
                      data={
                        workoutSummaries
                          .filter(ws => ws.workouts > 0)
                          .map(d => ({
                            day: new Date(d.date).toISOString().split('T')[0],
                            value: d.workouts
                          })) || []
                      }
                      height={450}
                      width={750}
                      from={start}
                      to={end}
                      emptyColor="#eeeeee"
                      colors={['#f47560', '#e8c1a0', '#97e3d5', '#61cdbb']}
                      margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
                      yearSpacing={40}
                      monthBorderColor={isDarkMode ? 'black' : 'white'}
                      monthSpacing={10}
                      dayBorderWidth={2}
                      dayBorderColor={isDarkMode ? 'black' : 'white'}
                      legends={[
                        {
                          anchor: 'bottom-right',
                          direction: 'row',
                          translateY: 36,
                          itemCount: 4,
                          itemWidth: 42,
                          itemHeight: 36,
                          itemsSpacing: 14,
                          itemDirection: 'right-to-left'
                        }
                      ]}
                    />
                  ) : (
                    <Typography variant="h6">
                      No data for this time period
                    </Typography>
                  )}
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
