import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import { useRecordContext } from 'react-admin';

export const ArrayValueField = props => {
  const record = useRecordContext(props);

  if (record && record[props.source]) {
    return record[props.source].map((item, index) => (
      <Chip key={index} label={item} />
    ));
  }
  return null;
};

ArrayValueField.propTypes = {
  source: PropTypes.string.isRequired
};

export default ArrayValueField;
