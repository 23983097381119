import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useInput } from 'ra-core';
import Editor from '@monaco-editor/react';
import useDebounce from '../hooks/useDebounce';

export const CodeEditor = props => {
  const {
    className,
    defaultValue = '',
    language = 'handlebars',
    width = '100%',
    resource,
    source,
    options,
    ...rest
  } = props;

  const editorRef = React.useRef(null);
  const [contentHeight, setContentHeight] = React.useState(500);
  const debouncedHeight = useDebounce(contentHeight, 100);

  React.useMemo(() => {
    if (editorRef.current) {
      editorRef.current.layout({ width, height: debouncedHeight });
    }

    return [debouncedHeight, width];
  }, [debouncedHeight, width]);

  const editorOptions = {
    //automaticLayout: true,
    minimap: { enabled: false },
    scrollBeyondLastLine: false,
    useTabStops: true,
    wordWrap: 'on',
    wrappingIndent: 'indent',
    wrappingStrategy: 'advanced'
    //...options
  };

  const editorProps = {};
  if (options?.readOnly) {
    editorProps.defaultValue = defaultValue;
  } else {
    const { field, id } = useInput({
      defaultValue,
      resource,
      source,
      type: 'text',
      ...rest
    });
    editorProps.defaultValue = field.value;
    editorProps.onChange = event => {
      field.onChange(event);
      // get lines count
      const lines = Math.max(event?.split('\n')?.length || 0, 10);
      const height = Math.min(lines * 18.1, 2500);
      setContentHeight(height);
    };
    editorProps.id = id;
  }

  React.useMemo(() => {
    const newHeight = Math.min(
      Math.max(editorProps.defaultValue.split('\n').length, 10) * 18.1,
      2500
    );
    if (newHeight !== contentHeight) {
      setContentHeight(newHeight);
    }
    return newHeight;
  }, [editorProps.defaultValue]);

  return (
    <Editor
      defaultLanguage={language}
      height={debouncedHeight + 'px'}
      width={width}
      theme="vs-dark"
      className={clsx('ra-input', `ra-input-${source}`, className)}
      options={editorOptions}
      {...editorProps}
    />
  );
};

CodeEditor.propTypes = {
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  defaultValue: PropTypes.any,
  language: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string
};
