import {
  Create,
  Datagrid,
  Edit,
  List,
  Resource,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput
} from 'react-admin';
import { FilterSidebar } from '../components/FilterSidebar';
import gql from 'graphql-tag';

export const name = 'WorkoutGroup';
export const fragments = {
  type: 'document',
  doc: gql`
    fragment FragWorkoutGroups on workoutGroups {
      id
      workoutId
      name
      colorCode
      exerciseRestDuration
      repeatRestDuration
      repeatCount
      order
      intros {
        id
        type
        fileName
        isDefault
        url
        __typename
      }
      exercises {
        id
        exerciseId
        duration
        reps
        order
        exercise {
          id
          name
          __typename
        }
        __typename
      }
      _count {
        exercises
        intros
        __typename
      }
      __typename
    }
  `
};

const list = () => (
  <List aside={<FilterSidebar />} sort={{ field: 'order', order: 'ASC' }}>
    <Datagrid rowClick="show">
      <TextField source="id" />
    </Datagrid>
  </List>
);

const show = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
    </SimpleShowLayout>
  </Show>
);

const edit = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm redirect="show">
      <TextInput source="name" />
    </SimpleForm>
  </Edit>
);

const create = () => (
  <Create>
    <SimpleForm redirect="list">
      <TextInput source="name" />
    </SimpleForm>
  </Create>
);

export default function AdminResource(props) {
  return (
    <Resource
      create={create}
      edit={edit}
      list={list}
      name={name}
      show={show}
      {...props}
    />
  );
}
