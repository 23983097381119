import { initializeApp } from 'firebase/app';
import {
  browserSessionPersistence,
  GoogleAuthProvider,
  initializeAuth,
  signOut,
  signInWithRedirect
} from 'firebase/auth';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_AUTH_APIKEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_AUTH_PROJECT_ID,
  storageBucket: process.env.REACT_APP_AUTH_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_AUTH_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_AUTH_APP_ID
};

const app = initializeApp(firebaseConfig);
const provider = new GoogleAuthProvider();
const auth = initializeAuth(app, {
  persistence: browserSessionPersistence
});

let user = auth.currentUser;

const whitelistEmails = [
  'parthib.biswas@weavers-web.com',
  'subhendu.sett@weavers-web.com'
];
const whitelistDomains = ['litmethod.com'];

auth.onAuthStateChanged(u => {
  user = u;
  if (
    user &&
    !(
      whitelistDomains.includes(user?.email?.split('@')?.[1]) ||
      whitelistEmails.includes(user?.email)
    )
  ) {
    throw new Error('You do not have access to this application.');
  }
});

const firebaseAuthProvider = {
  login: async () => {
    try {
      if (!user && auth.currentUser) {
        user = auth.currentUser;
      }
      if (user) {
        return Promise.resolve(user);
      }

      const result = await signInWithRedirect(auth, provider);
      if (
        !(
          whitelistDomains.includes(result.user.email?.split('@')?.[1]) ||
          whitelistEmails.includes(result.user.email)
        )
      ) {
        throw new Error('You do not have access to this application.');
      }
      user = result.user;
      Promise.resolve(user);
    } catch (e) {
      console.error(e);
      Promise.reject(e);
    }
  },
  logout: async () => {
    try {
      await signOut(auth);
      Promise.resolve();
    } catch (e) {
      console.error(e);
      Promise.reject(e);
    }
  },
  checkError: error => {
    const status = error.status;
    if (status === 401 || status === 403) {
      user = null;
      signOut.auth().finally(() => {
        return Promise.reject();
      });
    }
    return Promise.resolve();
  },
  checkAuth: async () => {
    await auth.authStateReady();
    if (auth.currentUser) {
      user = auth.currentUser;
    }
    if (user) {
      return Promise.resolve(user);
    }
    return Promise.reject();
  },
  getPermissions: () => {
    return Promise.resolve('admin');
  }
};

export default firebaseAuthProvider;
