import {
  Datagrid as RaDatagrid,
  DatagridBody as RaDatagridBody,
  DatagridRow as RaDatagridRow
} from 'react-admin';

export const DatagridRow = props => {
  if (!props.record) {
    return null;
  }

  const newProps = Object.assign({}, props);
  if (newProps.transformRecord) {
    newProps.record = newProps.transformRecord(
      Object.assign({}, newProps.record)
    );
  }

  return <RaDatagridRow {...newProps} />;
};

export const DatagridBody = props => (
  <RaDatagridBody
    {...props}
    row={<DatagridRow {...props} transformRecord={props.transformRecord} />}
  />
);
export const Datagrid = props => (
  <RaDatagrid {...props} body={<DatagridBody {...props} />} />
);
