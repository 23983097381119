import AccessibilityOutlinedIcon from '@mui/icons-material/AccessibilityOutlined';
import AudiotrackOutlinedIcon from '@mui/icons-material/AudiotrackOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import ClassOutlinedIcon from '@mui/icons-material/ClassOutlined';
import DeviceHubIcon from '@mui/icons-material/DeviceHubOutlined';
import EarbudsIcon from '@mui/icons-material/EarbudsOutlined';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenterOutlined';
import HealingOutlinedIcon from '@mui/icons-material/HealingOutlined';
import InterestsOutlinedIcon from '@mui/icons-material/InterestsOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import LiveTvOutlinedIcon from '@mui/icons-material/LiveTvOutlined';
import ManageHistoryOutlinedIcon from '@mui/icons-material/ManageHistoryOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import MUICategoryIcon from '@mui/icons-material/CategoryOutlined';
import OutlinedFlagIcon from '@mui/icons-material/OutlinedFlag';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import RowingOutlinedIcon from '@mui/icons-material/RowingOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SportsScoreOutlinedIcon from '@mui/icons-material/SportsScoreOutlined';
import StairsOutlinedIcon from '@mui/icons-material/StairsOutlined';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';

export const AccessoryIcon = EarbudsIcon;
export const AIEngineIcon = PsychologyOutlinedIcon;
export const AIPromptIcon = ChatOutlinedIcon;
export const AIPromptLogIcon = ManageHistoryOutlinedIcon;
export const BusinessIcon = BusinessOutlinedIcon;
export const CategoryIcon = MUICategoryIcon;
export const DeviceIcon = DeviceHubIcon;
export const EquipmentIcon = FitnessCenterIcon;
export const ExerciseSetIcon = MenuBookOutlinedIcon;
export const FeedFmIcon = AudiotrackOutlinedIcon;
export const GettingStartedIcon = OutlinedFlagIcon;
export const GoalIcon = SportsScoreOutlinedIcon;
export const InjuryIcon = HealingOutlinedIcon;
export const InstructorIcon = RowingOutlinedIcon;
export const InterestIcon = InterestsOutlinedIcon;
export const LiveWorkoutIcon = LiveTvOutlinedIcon;
export const MuscleGroupIcon = AccessibilityOutlinedIcon;
export const ProgramIcon = LibraryBooksOutlinedIcon;
export const RadioStationIcon = AudiotrackOutlinedIcon;
export const SetupIcon = SettingsOutlinedIcon;
export const SubscriptionIcon = SubscriptionsIcon;
export const UsersIcon = PeopleOutlinedIcon;
export const WorkoutIcon = ClassOutlinedIcon;
export const WorkoutLevelIcon = StairsOutlinedIcon;
export const ChartIcon = InsertChartOutlinedIcon;
