import { SimpleShowLayout, useRecordContext } from 'react-admin';

export const ShowLayout = props => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }

  const newProps = Object.assign({}, props);
  if (newProps.transformRecord) {
    newProps.record = newProps.transformRecord(Object.assign({}, record));
  }

  return <SimpleShowLayout {...newProps} />;
};
