import * as React from 'react';
import PropTypes from 'prop-types';
import { useInput } from 'ra-core';
import { sanitizeInputRestProps } from 'ra-ui-materialui';

export const HiddenInput = props => {
  const {
    defaultValue,
    format,
    onChange,
    parse,
    resource,
    source,
    validate,
    ...rest
  } = props;

  const { field, id } = useInput({
    defaultValue,
    format,
    onChange,
    parse,
    resource,
    source,
    validate,
    ...rest
  });

  React.useEffect(() => {
    if (field.value !== defaultValue) {
      field.onChange(defaultValue);
    }
  }, [defaultValue, field.value]);

  return (
    <input id={id} type="hidden" {...field} {...sanitizeInputRestProps(rest)} />
  );
};

HiddenInput.propTypes = {
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  defaultValue: PropTypes.any,
  format: PropTypes.func,
  parse: PropTypes.func,
  onChange: PropTypes.func,
  validate: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.func)
  ])
};

HiddenInput.defaultProps = {
  options: {}
};

export default HiddenInput;
