import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  ImageField,
  List,
  Resource,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  NumberInput
} from 'react-admin';
import { FilterSidebar } from '../components/FilterSidebar';
import { ImageInput } from '../components/ImageInput';
import { SliderInput } from '../components/SliderInput';
import { VideoField } from '../components/VideoField';
import gql from 'graphql-tag';

export const name = 'GettingStarted';
export const fragments = {
  type: 'document',
  doc: gql`
    fragment FragGettingStarted on gettingStarteds {
      id
      description
      duration
      imageUrl
      name
      videoName
      videoUrl
      order
      isPublished
      createdAt
      updatedAt
      __typename
    }
  `
};

const list = () => (
  <List
    aside={<FilterSidebar />}
    sort={{ field: 'name', order: 'ASC' }}
    title="Getting Started"
  >
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="description" />
      <ImageField source="imageUrl" />
      <TextField source="videoName" />
      <VideoField source="videoUrl" />
      <TextField source="duration" />
      <BooleanField source="isPublished" />
      <TextField source="order" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);

const show = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="description" />
      <ImageField source="imageUrl" />
      <TextField source="videoName" />
      <VideoField source="videoUrl" />
      <TextField source="duration" />
      <BooleanField source="isPublished" />
      <TextField source="order" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </SimpleShowLayout>
  </Show>
);

const edit = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm>
      <TextField source="id" />
      <TextInput source="name" />
      <TextInput source="description" multiline rows={4} fullWidth />
      <ImageInput source="imageUrl">
        <ImageField source="src" />
      </ImageInput>
      <TextInput source="videoName" />
      <TextInput source="videoUrl" />
      <SliderInput
        label="Duration (mins)"
        source="duration"
        sx={{ width: 250 }}
      />
      <BooleanInput source="isPublished" />
      <NumberInput source="order" />
    </SimpleForm>
  </Edit>
);

const create = () => (
  <Create>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="description" multiline rows={4} fullWidth />
      <ImageInput source="imageUrl">
        <ImageField source="src" />
      </ImageInput>
      <TextInput source="videoName" />
      <TextInput source="videoUrl" />
      <SliderInput
        label="Duration (mins)"
        source="duration"
        sx={{ width: 250 }}
      />
      <BooleanInput source="isPublished" />
      <NumberInput source="order" />
    </SimpleForm>
  </Create>
);

export default function AdminResource(props) {
  return (
    <Resource
      create={create}
      edit={edit}
      list={list}
      name={name}
      show={show}
      {...props}
    />
  );
}
