import {
  Datagrid,
  DateField,
  List,
  Resource,
  TextField,
  useRecordContext
} from 'react-admin';
import { CodeEditor } from '../components/CodeEditor';
import { FilterSidebar } from '../components/FilterSidebar';
import gql from 'graphql-tag';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

export const name = 'AiPromptLog';
export const fragments = {
  type: 'document',
  mode: 'extend',
  doc: gql`
    fragment FragAiPromptLogs on aiPromptLogs {
      id
      aiPromptId
      aiEngineId
      userId
      profileId
      prompt
      response
      context
      createdAt
      updatedAt
      aiPrompt {
        id
        name
        __typename
      }
      aiEngine {
        id
        name
        __typename
      }
      __typename
    }
  `
};

const ExpandedContent = () => {
  const record = useRecordContext();

  return (
    <Grid container spacing={1} style={{ maxWidth: '100%' }}>
      <Grid xs={12} md={6} style={{ maxWidth: '100%' }}>
        <CodeEditor
          defaultValue={record.prompt}
          source="prompt"
          language="md"
          options={{
            domReadOnly: true,
            readOnly: true
          }}
        />
      </Grid>
      <Grid xs={12} md={6} style={{ maxWidth: '100%' }}>
        <CodeEditor
          defaultValue={record.response}
          source="response"
          language="json"
          options={{
            domReadOnly: true,
            readOnly: true
          }}
        />
      </Grid>
      <Grid xs={12} style={{ maxWidth: '100%' }}>
        <Typography variant="h6">Context</Typography>
        <CodeEditor
          defaultValue={record.context}
          width={'80vw'}
          source="context"
          language="json"
          options={{
            domReadOnly: true,
            readOnly: true
          }}
        />
      </Grid>
    </Grid>
  );
};

const list = () => (
  <List aside={<FilterSidebar />} sort={{ field: 'name', order: 'ASC' }}>
    <Datagrid expand={<ExpandedContent />} isRowExpandable={() => true}>
      <TextField source="id" />
      <TextField source="aiPromptId" />
      <TextField source="aiEngineId" />
      <TextField source="userId" />
      <TextField source="profileId" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);

export default function AdminResource(props) {
  return <Resource list={list} name={name} {...props} />;
}
