import { AppBar as RAAppBar } from 'react-admin';
import { Box, Typography } from '@mui/material';

export const AppBar = props => (
  <RAAppBar {...props}>
    <Box flex="1">
      <Typography variant="h6" id="react-admin-title"></Typography>
    </Box>
  </RAAppBar>
);
