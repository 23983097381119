import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as LogoText } from '../assets/logoText.svg';
import { ReactComponent as LogoTextAlt } from '../assets/logoTextAlt.svg';
import Icon from '@mui/material/Icon';

export const Dashboard = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center'
      }}
    >
      {theme?.palette.mode === 'dark' ? (
        <Icon
          component={LogoTextAlt}
          sx={{
            height: 'auto',
            width: '50%',
            alignSelf: 'center'
          }}
        />
      ) : (
        <Icon
          component={LogoText}
          sx={{
            height: 'auto',
            width: '50%',
            alignSelf: 'center'
          }}
        />
      )}
    </Box>
  );
};
