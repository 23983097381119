import PropTypes from 'prop-types';
import { FilterList, FilterListItem } from 'react-admin';
import {
  endOfYesterday,
  startOfWeek,
  subWeeks,
  startOfMonth,
  subMonths
} from 'date-fns';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

export const TimePeriodList = ({
  icon = <AccessTimeIcon />,
  label = 'Time Period',
  source
}) => (
  <FilterList label={label} icon={icon}>
    <FilterListItem
      label="Today"
      value={{
        [`${source}_gte`]: endOfYesterday().toISOString(),
        [`${source}_lte`]: undefined
      }}
    />
    <FilterListItem
      label="This Week"
      value={{
        [`${source}_gte`]: startOfWeek(new Date()).toISOString(),
        [`${source}_lte`]: undefined
      }}
    />
    <FilterListItem
      label="Last Week"
      value={{
        [`${source}_gte`]: subWeeks(startOfWeek(new Date()), 1).toISOString(),
        [`${source}_lte`]: startOfWeek(new Date()).toISOString()
      }}
    />
    <FilterListItem
      label="This Month"
      value={{
        [`${source}_gte`]: startOfMonth(new Date()).toISOString(),
        [`${source}_lte`]: undefined
      }}
    />
    <FilterListItem
      label="Last Month"
      value={{
        [`${source}_gte`]: subMonths(startOfMonth(new Date()), 1).toISOString(),
        [`${source}_lte`]: startOfMonth(new Date()).toISOString()
      }}
    />
    <FilterListItem
      label="This Year"
      value={{
        [`${source}_gte`]: subMonths(
          startOfMonth(new Date()),
          12
        ).toISOString(),
        [`${source}_lte`]: undefined
      }}
    />
    <FilterListItem
      label="Earlier"
      value={{
        [`${source}_gte`]: undefined,
        [`${source}_lte`]: subMonths(startOfMonth(new Date()), 12).toISOString()
      }}
    />
  </FilterList>
);

TimePeriodList.propTypes = {
  icon: PropTypes.element,
  label: PropTypes.string,
  source: PropTypes.string.isRequired
};
