import { Login as RALogin } from 'react-admin';
import { StyledFirebaseAuth } from './StyledFirebaseAuth';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { setRecoil } from 'recoil-nexus';
import { authState } from '../recoil/atoms';

const whitelistEmails = [
  'parthib.biswas@weavers-web.com',
  'subhendu.sett@weavers-web.com'
];
const whitelistDomains = ['litmethod.com'];

const uiConfig = {
  signInFlow: 'popup',
  signInSuccessUrl: '#/',
  signInOptions: [GoogleAuthProvider.PROVIDER_ID],
  callbacks: {
    signInSuccessWithAuthResult: result => {
      if (
        !(
          whitelistDomains.includes(result?.additionalUserInfo?.profile?.hd) ||
          whitelistEmails.find(email =>
            email.includes(result?.additionalUserInfo?.profile?.hd)
          )
        )
      ) {
        throw new Error('You do not have access to this application.');
      }

      setRecoil(authState, true);

      return true;
    }
  }
};

export const Login = props => {
  return (
    <RALogin {...props}>
      <StyledFirebaseAuth firebaseAuth={getAuth()} uiConfig={uiConfig} />
    </RALogin>
  );
};
