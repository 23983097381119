import {
  Create,
  Edit,
  Datagrid,
  DateField,
  ImageField,
  List,
  Resource,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput
} from 'react-admin';
import { FilterSidebar } from '../components/FilterSidebar';
import { ImageInput } from '../components/ImageInput';
import gql from 'graphql-tag';

export const name = 'Accessory';
export const fragments = {
  type: 'document',
  doc: gql`
    fragment FragAccessories on accessories {
      id
      iconImageUrl
      productImageUrl
      name
      discount
      addToCartUrl
      createdAt
      updatedAt
      _count {
        exercises
        workouts
        users
        userProfiles
        __typename
      }
      __typename
    }
  `
};

const list = () => (
  <List aside={<FilterSidebar />} sort={{ field: 'name', order: 'ASC' }}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <ImageField source="iconImageUrl" />
      <ImageField source="productImageUrl" />
      <TextField source="addToCartUrl" />
      <TextField source="discount" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);

const show = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <ImageField source="iconImageUrl" />
      <ImageField source="productImageUrl" />
      <TextField source="addToCartUrl" />
      <TextField source="discount" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </SimpleShowLayout>
  </Show>
);

const edit = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm>
      <TextField source="id" />
      <TextInput source="name" />
      <ImageInput source="iconImageUrl" />
      <ImageInput source="productImageUrl" />
      <TextInput source="addToCartUrl" />
      <TextInput source="discount" />
    </SimpleForm>
  </Edit>
);

const create = () => (
  <Create>
    <SimpleForm>
      <TextInput source="name" />
      <ImageInput source="iconImageUrl" create />
      <ImageInput source="productImageUrl" create />
      <TextInput source="addToCartUrl" />
      <TextInput source="discount" />
    </SimpleForm>
  </Create>
);

export default function AdminResource(props) {
  return (
    <Resource
      create={create}
      edit={edit}
      list={list}
      name={name}
      show={show}
      {...props}
    />
  );
}
