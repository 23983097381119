import PropTypes from 'prop-types';
import { ResponsiveBar } from '@nivo/bar';
import { useTheme } from 'react-admin';
import { Canvg } from 'canvg';
import { saveAs } from 'file-saver';
import { useRef } from 'react';
import { Button, ButtonGroup } from '@mui/material';
import { valueFormatter } from '../helpers/graphs';

export const BarGraph = ({ data, ...rest }) => {
  const theme = useTheme();
  const isDarkMode = (theme?.[0]?.palette?.mode || theme?.[0]) === 'dark';
  const textColor = isDarkMode ? 'white' : 'black';
  const textColorInverse = isDarkMode ? 'black' : 'white';
  const gridColor = isDarkMode
    ? 'rgba(255, 255, 255, 0.1)'
    : 'rgba(0, 0, 0, 0.1)';
  const tooltipBackground = isDarkMode ? 'black' : 'white';
  const tooltipTextColor = isDarkMode ? 'white' : 'black';

  const chartRef = useRef(null);

  const downloadSVGAsPNG = () => {
    if (chartRef.current) {
      const svg = chartRef.current.querySelector('svg');
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const v = Canvg.fromString(ctx, svg.outerHTML);
      v.render().then(() => {
        canvas.toBlob(blob => {
          saveAs(blob, 'chart.png');
        });
      });
    }
  };

  const downloadSVG = () => {
    if (chartRef.current) {
      const svg = chartRef.current.querySelector('svg');
      const serializer = new XMLSerializer();
      const svgBlob = new Blob([serializer.serializeToString(svg)], {
        type: 'image/svg+xml'
      });
      saveAs(svgBlob, 'chart.svg');
    }
  };

  const downloadDataAsCSV = () => {
    let csv = Object.keys(data[0]).join(',') + '\n';
    data.forEach(row => {
      csv += Object.values(row).join(',') + '\n';
    });
    const blob = new Blob([csv], { type: 'text/csv' });
    saveAs(blob, 'data.csv');
  };

  return (
    <div
      style={{ textAlign: 'center', width: '100%', height: '100%' }}
      ref={chartRef}
    >
      <ResponsiveBar
        data={data}
        colors={{ scheme: isDarkMode ? 'dark2' : 'nivo' }}
        valueFormat={valueFormatter}
        padding={0.25}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        labelSkipWidth={0}
        labelSkipHeight={0}
        margin={{ top: 10, right: 10, bottom: 100, left: 60 }}
        {...rest}
        theme={{
          axis: {
            ticks: {
              text: {
                fill: textColor
              }
            },
            legend: {
              text: {
                fill: textColor
              }
            }
          },
          labels: {
            text: {
              fill: textColor,
              paintOrder: 'stroke',
              strokeWidth: 2.5,
              stroke: textColorInverse
            }
          },
          grid: {
            line: {
              stroke: gridColor
            }
          },
          tooltip: {
            container: {
              background: tooltipBackground,
              color: tooltipTextColor
            }
          }
        }}
      />
      <ButtonGroup variant="contained" style={{ marginTop: '10px' }}>
        <Button onClick={downloadSVGAsPNG}>Download PNG</Button>
        <Button onClick={downloadSVG}>Download SVG</Button>
        <Button onClick={downloadDataAsCSV}>Download CSV</Button>
      </ButtonGroup>
    </div>
  );
};

BarGraph.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  ...ResponsiveBar.propTypes
};
