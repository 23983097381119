const convertDate = inputDate => {
  const date = new Date(inputDate);
  date.setUTCHours(0, 0, 0, 0);
  return date.toISOString().split('T')[0];
};

export const lineGraphDataMinutes = function (_data) {
  if (!_data || !_data.length) return [];
  let convertedData = [];
  _data.forEach(d => {
    convertedData.push({
      x: new Date(d.date).toISOString().split('T')[0],
      y: d.minutes
    });
  });
  return [
    {
      id: 'Minutes',
      data: convertedData,
      color: 'hsl(14, 70%, 50%)'
    }
  ];
};

export const lineGraphDataWorkouts = function (_data) {
  if (!_data || !_data.length) return [];
  let convertedData = [];
  _data.forEach(d => {
    convertedData.push({
      x: new Date(d.date).toISOString().split('T')[0],
      y: d.workouts
    });
  });
  return [
    {
      id: 'Workouts',
      data: convertedData,
      color: 'hsl(14, 70%, 50%)'
    }
  ];
};

export const lineGraphDataSignups = function (_data) {
  if (!_data) return [];
  let convertedData = [];
  for (let key in _data) {
    const createdObj = { x: convertDate(key), y: parseInt(_data[key]) };
    convertedData.push(createdObj);
  }
  return [
    {
      id: 'Signups',
      data: convertedData,
      color: 'hsl(14, 70%, 50%)'
    }
  ];
};

export const barGraphDataMinutes = function (_data) {
  let convertedData = [];
  _data.forEach(d => {
    convertedData.push({
      date: new Date(d.date).toISOString().split('T')[0],
      minutes: d.minutes
    });
  });
  return convertedData;
};

export const barGraphDataWorkouts = function (_data) {
  let convertedData = [];
  _data.forEach(d => {
    convertedData.push({
      date: new Date(d.date).toISOString().split('T')[0],
      workouts: d.workouts
    });
  });
  return convertedData;
};

export const barGraphDataSignups = function (_data) {
  if (!_data) return [];
  let convertedData = [];
  for (let key in _data) {
    const createdObj = {
      // date: convertDate(key),
      date: key,
      signups: parseInt(_data[key])
    };
    convertedData.push(createdObj);
  }
  return convertedData;
};

export const valueFormatter = num => {
  if (typeof num === 'number') {
    return new Intl.NumberFormat(navigator.language).format(num);
  }
  return num;
};
