import { Create, Edit, List, Resource, Show } from 'react-admin';
import gql from 'graphql-tag';

export const name = 'ExerciseToWorkoutGroup';
export const fragments = {
  type: 'document',
  doc: gql`
    fragment FragExerciseToWorkoutGroups on exerciseToWorkoutGroups {
      id
      groupId
      exerciseId
      duration
      reps
      _count {
        __typename
      }
      __typename
    }
  `
};

const list = () => <List />;

const show = () => <Show />;

const edit = () => <Edit mutationMode="pessimistic" />;

const create = () => <Create />;

export default function AdminResource(props) {
  return (
    <Resource
      create={create}
      edit={edit}
      list={list}
      name={name}
      show={show}
      {...props}
    />
  );
}
