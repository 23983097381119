import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import { FieldTitle, useInput, warning, useChoicesContext } from 'ra-core';

import {
  sanitizeInputRestProps,
  InputHelperText,
  Labeled,
  LinearProgress
} from 'react-admin';

import { CheckboxGroupInputItem } from 'ra-ui-materialui/dist/esm/input/CheckboxGroupInputItem';

/**
 * An Input component for a checkbox group, using an array of objects for the options
 *
 * Pass possible options as an array of objects in the 'choices' attribute.
 *
 * The expected input must be an array of identifiers (e.g. [12, 31]) which correspond to
 * the 'optionValue' of 'choices' attribute objects.
 *
 * By default, the options are built from:
 *  - the 'id' property as the option value,
 *  - the 'name' property as the option text
 * @example
 * const choices = [
 *     { id: 12, name: 'Ray Hakt' },
 *     { id: 31, name: 'Ann Gullar' },
 *     { id: 42, name: 'Sean Phonee' },
 * ];
 * <CheckboxGroupInput source="recipients" choices={choices} />
 *
 * You can also customize the properties to use for the option name and value,
 * thanks to the 'optionText' and 'optionValue' attributes.
 * @example
 * const choices = [
 *    { _id: 123, full_name: 'Leo Tolstoi' },
 *    { _id: 456, full_name: 'Jane Austen' },
 * ];
 * <CheckboxGroupInput source="recipients" choices={choices} optionText="full_name" optionValue="_id" />
 *
 * `optionText` also accepts a function, so you can shape the option text at will:
 * @example
 * const choices = [
 *    { id: 123, first_name: 'Leo', last_name: 'Tolstoi' },
 *    { id: 456, first_name: 'Jane', last_name: 'Austen' },
 * ];
 * const optionRenderer = choice => `${choice.first_name} ${choice.last_name}`;
 * <CheckboxGroupInput source="recipients" choices={choices} optionText={optionRenderer} />
 *
 * `optionText` also accepts a React Element, that can access
 * the related choice through the `useRecordContext` hook. You can use Field components there.
 * @example
 * const choices = [
 *    { id: 123, first_name: 'Leo', last_name: 'Tolstoi' },
 *    { id: 456, first_name: 'Jane', last_name: 'Austen' },
 * ];
 * const FullNameField = () => {
 *     const record = useRecordContext();
 *     return <span>{record.first_name} {record.last_name}</span>;
 * };
 *
 * <CheckboxGroupInput source="recipients" choices={choices} optionText={<FullNameField />}/>
 *
 * The choices are translated by default, so you can use translation identifiers as choices:
 * @example
 * const choices = [
 *    { id: 'programming', name: 'myroot.category.programming' },
 *    { id: 'lifestyle', name: 'myroot.category.lifestyle' },
 *    { id: 'photography', name: 'myroot.category.photography' },
 * ];
 *
 * However, in some cases (e.g. inside a `<ReferenceArrayInput>`), you may not want
 * the choice to be translated. In that case, set the `translateChoice` prop to false.
 * @example
 * <CheckboxGroupInput source="tags" choices={choices} translateChoice={false}/>
 *
 * The object passed as `options` props is passed to the MUI <Checkbox> components
 */
export const NestedCheckboxGroupInput = props => {
  const {
    choices: choicesProp,
    className,
    format,
    helperText,
    label,
    isLoading: isLoadingProp,
    isFetching: isFetchingProp,
    margin = 'dense',
    onBlur,
    onChange,
    optionText = 'name',
    optionValue = 'id',
    optionParentValue = 'parentId',
    parse,
    resource: resourceProp,
    row = false,
    source: sourceProp,
    translateChoice = true,
    validate,
    ...rest
  } = props;

  const { allChoices, isLoading, resource, source } = useChoicesContext({
    choices: choicesProp,
    isFetching: isFetchingProp,
    isLoading: isLoadingProp,
    resource: resourceProp,
    source: sourceProp
  });

  const tieredChoices = useMemo(() => {
    if (!allChoices?.length) {
      return [];
    }

    const choices = {};
    const children = {};
    for (const choice of allChoices) {
      const parent = choice[optionParentValue];
      const value = choice[optionValue];

      if (parent) {
        if (!children[parent]) {
          children[parent] = [];
        }
        children[parent].push(choice);
        continue;
      }

      choices[value] = choice;
    }
    for (const parent in children) {
      if (choices[parent]) {
        choices[parent].children = children[parent];
      }
    }

    return Object.values(choices);
  }, [allChoices, optionParentValue, optionValue]);

  warning(
    source === undefined,
    `If you're not wrapping the CheckboxGroupInput inside a ReferenceArrayInput, you must provide the source prop`
  );

  warning(
    tieredChoices === undefined,
    `If you're not wrapping the CheckboxGroupInput inside a ReferenceArrayInput, you must provide the choices prop`
  );

  const {
    field: { onChange: formOnChange, onBlur: formOnBlur, value },
    fieldState: { error, invalid, isTouched },
    formState: { isSubmitted },
    id,
    isRequired
  } = useInput({
    format,
    parse,
    resource,
    source,
    validate,
    onChange,
    onBlur,
    ...rest
  });

  const handleCheck = useCallback(
    (event, isChecked) => {
      let newValue;

      if (
        allChoices.every(item => typeof get(item, optionValue) === 'number')
      ) {
        try {
          // try to convert string value to number, e.g. '123'
          newValue = JSON.parse(event.target.value);
        } catch (e) {
          // impossible to convert value, e.g. 'abc'
          newValue = event.target.value;
        }
      } else {
        newValue = event.target.value;
      }

      if (isChecked) {
        formOnChange([...(value || []), ...[newValue]]);
      } else {
        formOnChange(value.filter(v => v != newValue)); // eslint-disable-line eqeqeq
      }
      formOnBlur(); // Ensure field is flagged as touched
    },
    [allChoices, formOnChange, formOnBlur, optionValue, value]
  );

  if (isLoading && tieredChoices?.length === 0) {
    return (
      <Labeled
        id={id}
        label={label}
        source={source}
        resource={resource}
        className={clsx('ra-input', `ra-input-${source}`, className)}
        isRequired={isRequired}
        {...rest}
      >
        <LinearProgress />
      </Labeled>
    );
  }

  return (
    <StyledFormControl
      component="fieldset"
      margin={margin}
      error={(isTouched || isSubmitted) && invalid}
      className={clsx('ra-input', `ra-input-${source}`, className)}
      {...sanitizeRestProps(rest)}
    >
      <FormLabel component="legend" className={NestedCheckboxGroupInput.label}>
        <FieldTitle
          label={label}
          source={source}
          resource={resource}
          isRequired={isRequired}
        />
      </FormLabel>
      <div style={{ display: 'flex', flexDirection: row ? 'column' : 'row' }}>
        {tieredChoices
          ?.sort((a, b) => a[optionText]?.localeCompare(b[optionText]))
          ?.map(choice => (
            <>
              <FormGroup row={row}>
                <CheckboxGroupInputItem
                  key={get(choice, optionValue)}
                  choice={choice}
                  id={id}
                  onChange={handleCheck}
                  optionText={optionText}
                  optionValue={optionValue}
                  translateChoice={translateChoice}
                  value={value}
                  {...sanitizeRestProps(rest)}
                />
                {choice?.children
                  ?.sort((a, b) => a[optionText]?.localeCompare(b[optionText]))
                  ?.map(child => (
                    <CheckboxGroupInputItem
                      key={get(child, optionValue)}
                      choice={child}
                      id={id}
                      onChange={handleCheck}
                      optionText={optionText}
                      optionValue={optionValue}
                      translateChoice={translateChoice}
                      value={value}
                      style={{ [row ? 'columnGap' : 'marginLeft']: 32 }}
                      {...sanitizeRestProps(rest)}
                    />
                  ))}
              </FormGroup>
            </>
          ))}
      </div>
      <FormHelperText>
        <InputHelperText
          touched={isTouched || isSubmitted}
          error={error?.message}
          helperText={helperText}
        />
      </FormHelperText>
    </StyledFormControl>
  );
};

const sanitizeRestProps = ({ ...rest }) => sanitizeInputRestProps(rest);

NestedCheckboxGroupInput.propTypes = {
  choices: PropTypes.arrayOf(PropTypes.any),
  className: PropTypes.string,
  source: PropTypes.string,
  optionText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element
  ]),
  optionValue: PropTypes.string,
  optionParent: PropTypes.string,
  row: PropTypes.bool,
  resource: PropTypes.string,
  translateChoice: PropTypes.bool,
  format: PropTypes.func,
  parse: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  validate: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.func)
  ]),
  ...FormControl.propTypes
};

const PREFIX = 'SLNestedCheckboxGroupInput';

export const NestedCheckboxGroupInputClasses = {
  label: `${PREFIX}-label`
};

const StyledFormControl = styled(FormControl, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root
})(({ theme }) => ({
  [`& .${NestedCheckboxGroupInputClasses.label}`]: {
    transform: 'translate(0, 8px) scale(0.75)',
    transformOrigin: `top ${theme.direction === 'ltr' ? 'left' : 'right'}`
  }
}));
