import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';

export const RecordTitle = ({ source, prefix }) => {
  const record = useRecordContext();

  let title = [prefix, record?.[source]].filter(item => !!item).join(': ');

  return <span>{title}</span>;
};

RecordTitle.propTypes = {
  prefix: PropTypes.string,
  source: PropTypes.string.isRequired
};
