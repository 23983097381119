import * as React from 'react';
import { Menu as RAMenu } from 'react-admin';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import IconExpandLess from '@mui/icons-material/ExpandLess';
import IconExpandMore from '@mui/icons-material/ExpandMore';
import ListItemText from '@mui/material/ListItemText';
import {
  AccessoryIcon,
  AIEngineIcon,
  AIPromptIcon,
  AIPromptLogIcon,
  BusinessIcon,
  CategoryIcon,
  ChartIcon,
  DeviceIcon,
  EquipmentIcon,
  ExerciseSetIcon,
  GettingStartedIcon,
  GoalIcon,
  InjuryIcon,
  InstructorIcon,
  InterestIcon,
  MuscleGroupIcon,
  ProgramIcon,
  RadioStationIcon,
  SetupIcon,
  SubscriptionIcon,
  WorkoutIcon,
  WorkoutLevelIcon
} from './Icon';

export const Menu = props => {
  const [exerciseOpen, setExerciseOpen] = React.useState(false);
  const [programOpen, setProgramOpen] = React.useState(false);
  const [setupOpen, setSetupOpen] = React.useState(false);
  const [subscriptionOpen, setSubscriptionOpen] = React.useState(false);

  return (
    <RAMenu {...props}>
      <RAMenu.DashboardItem />

      <RAMenu.Item
        to="/GettingStarted"
        primaryText="Getting Started"
        leftIcon={<GettingStartedIcon />}
      />

      <RAMenu.Item
        to="/"
        primaryText={
          <>
            <ListItemText primary="Exercise Library" />
            {exerciseOpen ? <IconExpandLess /> : <IconExpandMore />}
          </>
        }
        leftIcon={<ExerciseSetIcon />}
        onClick={event => {
          event.preventDefault();
          setExerciseOpen(!exerciseOpen);
        }}
      />
      <Collapse in={exerciseOpen} timeout="auto" unmountOnExit>
        <Divider />
        <RAMenu {...props}>
          <RAMenu.Item
            to="/Exercise"
            primaryText="Exercises"
            leftIcon={<ExerciseSetIcon />}
          />
        </RAMenu>
      </Collapse>

      <RAMenu.Item
        to="/"
        primaryText={
          <>
            <ListItemText primary="Programs" />
            {programOpen ? <IconExpandLess /> : <IconExpandMore />}
          </>
        }
        leftIcon={<ProgramIcon />}
        onClick={event => {
          event.preventDefault();
          setProgramOpen(!programOpen);
        }}
      />
      <Collapse in={programOpen} timeout="auto" unmountOnExit>
        <Divider />
        <RAMenu {...props}>
          <RAMenu.Item
            to="/Program"
            primaryText="Programs"
            leftIcon={<ProgramIcon />}
          />
          <RAMenu.Item
            to="/ProgramSchedule"
            primaryText="Program Schedule"
            leftIcon={<ProgramIcon />}
          />
        </RAMenu>
      </Collapse>

      <RAMenu.Item
        to="/Workout"
        primaryText="Workouts"
        leftIcon={<WorkoutIcon />}
      />

      <RAMenu.Item
        to="/"
        primaryText={
          <>
            <ListItemText primary="Subscriptions" />
            {subscriptionOpen ? <IconExpandLess /> : <IconExpandMore />}
          </>
        }
        leftIcon={<SubscriptionIcon />}
        onClick={event => {
          event.preventDefault();
          setSubscriptionOpen(!subscriptionOpen);
        }}
      />
      <Collapse in={subscriptionOpen} timeout="auto" unmountOnExit>
        <Divider />
        <RAMenu {...props}>
          <RAMenu.Item
            to="/Business"
            primaryText="Businesses"
            leftIcon={<BusinessIcon />}
          />
          <RAMenu.Item
            to="/Analytics"
            primaryText="Analytics"
            leftIcon={<ChartIcon />}
          />
        </RAMenu>
      </Collapse>

      <RAMenu.Item
        to="/"
        primaryText={
          <>
            <ListItemText primary="Setup" />
            {setupOpen ? <IconExpandLess /> : <IconExpandMore />}
          </>
        }
        leftIcon={<SetupIcon />}
        onClick={event => {
          event.preventDefault();
          setSetupOpen(!setupOpen);
        }}
      />

      <Collapse in={setupOpen} timeout="auto" unmountOnExit>
        <Divider />
        <RAMenu {...props}>
          <RAMenu.Item
            to="/Accessory"
            primaryText="Accessories"
            leftIcon={<AccessoryIcon />}
          />
          <RAMenu.Item
            to="/AiEngine"
            primaryText="AI Engines"
            leftIcon={<AIEngineIcon />}
          />
          <RAMenu.Item
            to="/AiPrompt"
            primaryText="AI Prompts"
            leftIcon={<AIPromptIcon />}
          />
          <RAMenu.Item
            to="/AiPromptLog"
            primaryText="AI Prompt Logs"
            leftIcon={<AIPromptLogIcon />}
          />
          <RAMenu.Item
            to="/Category"
            primaryText="Categories"
            leftIcon={<CategoryIcon />}
          />
          <RAMenu.Item
            to="/Device"
            primaryText="Devices"
            leftIcon={<DeviceIcon />}
          />
          <RAMenu.Item
            to="/Equipment"
            primaryText="Equipment"
            leftIcon={<EquipmentIcon />}
          />
          <RAMenu.Item to="/Goal" primaryText="Goals" leftIcon={<GoalIcon />} />

          <RAMenu.Item
            to="/Instructor"
            primaryText="Instructors"
            leftIcon={<InstructorIcon />}
          />

          <RAMenu.Item
            to="/Injury"
            primaryText="Injuries"
            leftIcon={<InjuryIcon />}
          />
          <RAMenu.Item
            to="/Interest"
            primaryText="Interests"
            leftIcon={<InterestIcon />}
          />
          <RAMenu.Item
            to="/MuscleGroup"
            primaryText="Muscle Groups"
            leftIcon={<MuscleGroupIcon />}
          />
          <RAMenu.Item
            to="/RadioStation"
            primaryText="Radio Stations"
            leftIcon={<RadioStationIcon />}
          />
          <RAMenu.Item
            to="/WorkoutLevel"
            primaryText="Workout Levels"
            leftIcon={<WorkoutLevelIcon />}
          />
        </RAMenu>
      </Collapse>
    </RAMenu>
  );
};
