import {
  AutocompleteInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  ImageField,
  List,
  NumberInput,
  Resource,
  ReferenceInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput
} from 'react-admin';
import { CategoryFilterSidebar } from '../components/FilterSidebar';
import { ImageInput } from '../components/ImageInput';
import gql from 'graphql-tag';

export const name = 'Category';
export const fragments = {
  type: 'document',
  doc: gql`
    fragment FragCategories on categories {
      id
      parentId
      iconImageUrl
      imageUrl
      name
      aiPrompt
      radioStationId
      order
      createdAt
      updatedAt
      _count {
        children
        programs
        workouts
        __typename
      }
      children {
        id
        name
        __typename
      }
      parent {
        id
        name
        __typename
      }
      radioStation {
        id
        name
        __typename
      }
      __typename
    }
  `
};

const list = () => (
  <List
    aside={<CategoryFilterSidebar />}
    sort={{ field: 'name', order: 'ASC' }}
  >
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="parent.name" title="Parent Category" />
      <ImageField source="iconImageUrl" />
      <ImageField source="imageUrl" />
      <TextField source="order" />
      <TextField source="radioStation.name" title="Radio Station" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);

const show = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="aiPrompt" multiline />
      <TextField source="parent.name" title="Parent Category" />
      <ImageField source="iconImageUrl" />
      <ImageField source="imageUrl" />
      <TextField source="order" />
      <TextField source="radioStation.name" title="Radio Station" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </SimpleShowLayout>
  </Show>
);

const edit = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm>
      <TextField source="id" />
      <TextInput source="name" />
      <TextInput source="aiPrompt" rows={4} multiline />
      <ReferenceInput source="parent_id" reference="Category">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ImageInput source="iconImageUrl" />
      <ImageInput source="imageUrl" />
      <NumberInput source="order" />
      <ReferenceInput source="radioStation_id" reference="RadioStation">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

const create = () => (
  <Create>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="aiPrompt" rows={4} multiline />
      <ReferenceInput source="parent_id" reference="Category">
        <AutocompleteInput optionText="name" defaultValue={null} />
      </ReferenceInput>
      <ImageInput source="iconImageUrl" create />
      <ImageInput source="imageUrl" create />
      <NumberInput source="order" />
      <ReferenceInput source="radioStation_id" reference="RadioStation">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export default function AdminResource(props) {
  return (
    <Resource
      create={create}
      edit={edit}
      list={list}
      name={name}
      show={show}
      {...props}
    />
  );
}
