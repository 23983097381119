import {
  ArrayInput,
  Button,
  Confirm,
  Create,
  DatagridConfigurable,
  DateField,
  DateInput,
  Edit,
  FunctionField,
  List,
  NumberField,
  NumberInput,
  ReferenceManyField,
  Resource,
  Show,
  SimpleForm,
  SimpleFormIterator,
  SimpleShowLayout,
  TextField,
  TextInput,
  useListContext,
  useNotify,
  useRecordContext,
  useRefresh,
  useSafeSetState,
  useTranslate,
  useUpdate
} from 'react-admin';
import PropTypes from 'prop-types';
import inflection from 'inflection';
import ArrayValueField from '../components/ArrayValueField';
import { RecordTitle } from '../components/RecordTitle';
import { FilterSidebar } from '../components/FilterSidebar';
import gql from 'graphql-tag';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { Fragment } from 'react';

export const name = 'Business';
export const fragments = {
  type: 'document',
  doc: gql`
    fragment FragBusinesses on businesses {
      id
      name
      domains
      userLimit
      subscriptionStartAt
      subscriptionEndAt
      createdAt
      updatedAt
      users {
        id
      }
      _count {
        users
        __typename
      }
      __typename
    }
  `
};

const convertStringToDate = date => {
  if (date && !(date instanceof Date)) {
    date = new Date(date);
  }
  return date;
};

const list = () => (
  <List
    title="Businesses"
    aside={<FilterSidebar />}
    sort={{ field: 'name', order: 'ASC' }}
  >
    <DatagridConfigurable rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <ArrayValueField source="domains" />
      <NumberField source="userLimit" />
      <FunctionField
        label="User Count"
        render={record => {
          return record.users ? record.users.length : 0;
        }}
      />
      <DateField source="subscriptionStartAt" />
      <DateField source="subscriptionEndAt" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </DatagridConfigurable>
  </List>
);

const UserRelationBulkActionButtons = () => {
  const [open, setOpen] = useSafeSetState(false);
  const record = useRecordContext();
  const { selectedIds, onUnselectItems, resource } = useListContext();
  const [update, { isLoading }] = useUpdate();
  const refresh = useRefresh();
  const notify = useNotify();
  const translate = useTranslate();

  return (
    <>
      <Button label="Delete Users" onClick={() => setOpen(true)} />
      <Confirm
        isOpen={open}
        loading={isLoading}
        title="Remove Users from Business"
        content="Are you sure you want to remove these users from this business?"
        translateOptions={{
          smart_count: selectedIds.length,
          name: translate(`resources.${resource}.forcedCaseName`, {
            smart_count: selectedIds.length,
            _: inflection.humanize(
              translate(`resources.${resource}.name`, {
                smart_count: selectedIds.length,
                _: inflection.inflect(resource, selectedIds.length)
              }),
              true
            )
          })
        }}
        onConfirm={() => {
          if (!record?.id) {
            return notify('Error: record.id does not exist', {
              type: 'error'
            });
          }
          if (!selectedIds?.length) {
            return notify('Error: no selectedIds', {
              type: 'error'
            });
          }

          return update(
            name,
            {
              id: record?.id,
              data: {
                users_ids: record.users_ids.filter(
                  uid => !selectedIds.includes(uid)
                )
              },
              previousData: {
                users_ids: record.users_ids
              }
            },
            {
              onSuccess: () => {
                refresh();
                notify('ra.notification.deleted', {
                  type: 'info',
                  messageArgs: { smart_count: selectedIds.length },
                  undoable: false
                });
                onUnselectItems();
                setOpen(false);
              },
              onError: () => {
                notify('Error: Failed to remove user(s) from business', {
                  type: 'error'
                });
              }
            }
          );
        }}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

const UserRelationAddUsersDialog = ({ onSuccess }) => {
  const [open, setOpen] = useSafeSetState(false);
  const [userIds, setUserIds] = useSafeSetState([]);
  const record = useRecordContext();
  const [update, { isLoading }] = useUpdate();
  const refresh = useRefresh();
  const notify = useNotify();

  return (
    <>
      <Box flex={1} flexGrow={1} sx={{ alignItems: 'flex-end' }}>
        <Button label="Add Users" onClick={() => setOpen(true)} />
      </Box>
      <Dialog open={open} isLoading={isLoading}>
        <DialogTitle>Add Users to Business</DialogTitle>
        <DialogContent>
          <List
            exporter={false}
            title="Users"
            resource="User"
            filter={{
              businessId: {
                equals: null
              }
            }}
            filters={[
              <TextInput key="email" source="email" alwaysOn />,
              <TextInput key="firstName" source="firstName" alwaysOn />,
              <TextInput key="lastName" source="lastName" alwaysOn />
            ]}
          >
            <DatagridConfigurable
              bulkActionButtons={<Fragment />}
              rowClick="toggleSelection"
              selectedIds={userIds}
              onSelect={ids => {
                setUserIds(ids);
              }}
              onToggleItem={id => {
                const index = userIds.indexOf(id);
                setUserIds(
                  index !== -1
                    ? [
                        ...userIds.slice(0, userIds.indexOf(id)),
                        ...userIds.slice(userIds.indexOf(id) + 1)
                      ]
                    : [...userIds, id]
                );
              }}
              onUnselect={ids => {
                setUserIds(userIds.filter(id => !ids.includes(id)));
              }}
            >
              <TextField source="email" />
              <TextField source="firstName" />
              <TextField source="lastName" />
            </DatagridConfigurable>
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isLoading}
            label="Cancel"
            onClick={() => setOpen(false)}
          />
          <Button
            disabled={isLoading}
            label="Add"
            onClick={() => {
              if (!record?.id) {
                return notify('Error: record.id does not exist', {
                  type: 'error'
                });
              }
              if (!userIds?.length) {
                return notify('Error: no users selected', {
                  type: 'error'
                });
              }

              return update(
                name,
                {
                  id: record?.id,
                  data: {
                    users_ids: [...record.users_ids, ...userIds]
                  },
                  previousData: {
                    users_ids: record.users_ids
                  }
                },
                {
                  onSuccess: () => {
                    refresh();
                    notify('Users have been assigned!', {
                      type: 'success'
                    });
                    setOpen(false);
                    if (typeof onSuccess === 'function') {
                      onSuccess();
                    }
                  },
                  onError: () => {
                    notify('Error: Failed to remove user(s) from business', {
                      type: 'error'
                    });
                  }
                }
              );
            }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};
UserRelationAddUsersDialog.propTypes = {
  onSuccess: PropTypes.func
};

const show = () => {
  const refresh = useRefresh();

  return (
    <Show title={<RecordTitle source="name" prefix="Business" />}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="name" />
        <ArrayValueField source="domains" />
        <NumberField source="userLimit" />
        <FunctionField
          label="User Count"
          render={record => {
            return record.users ? record.users.length : 0;
          }}
        />
        <DateField source="subscriptionStartAt" />
        <DateField source="subscriptionEndAt" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />

        <ReferenceManyField
          label="Users"
          reference="User"
          target="businessId"
          source="id"
        >
          <UserRelationAddUsersDialog onSuccess={() => refresh()} />
          <DatagridConfigurable
            rowClick="toggleSelection"
            resource="User"
            bulkActionButtons={<UserRelationBulkActionButtons />}
          >
            <TextField source="id" />
            <TextField source="email" />
            <TextField source="firstName" />
            <TextField source="lastName" />
          </DatagridConfigurable>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
};

const edit = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm>
      <TextField source="id" />
      <TextInput source="name" />
      <ArrayInput source="domains">
        <SimpleFormIterator>
          <TextInput />
        </SimpleFormIterator>
      </ArrayInput>
      <NumberInput source="userLimit" />
      <DateInput
        source="subscriptionStartAt"
        parse={convertStringToDate}
        format={convertStringToDate}
      />
      <DateInput
        source="subscriptionEndAt"
        parse={convertStringToDate}
        format={convertStringToDate}
      />
    </SimpleForm>
  </Edit>
);

const create = () => (
  <Create>
    <SimpleForm>
      <TextInput source="name" />
      <ArrayInput source="domains">
        <SimpleFormIterator>
          <TextInput />
        </SimpleFormIterator>
      </ArrayInput>
      <NumberInput source="userLimit" />
      <DateInput
        source="subscriptionStartAt"
        parse={convertStringToDate}
        format={convertStringToDate}
      />
      <DateInput
        source="subscriptionEndAt"
        parse={convertStringToDate}
        format={convertStringToDate}
      />
    </SimpleForm>
  </Create>
);

export default function AdminResource(props) {
  return (
    <Resource
      create={create}
      edit={edit}
      list={list}
      name={name}
      show={show}
      {...props}
    />
  );
}
