import {
  FormDataConsumer,
  ImageField,
  ImageInput as RAImageInput,
  Labeled
} from 'react-admin';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';

export const ImageInput = props => {
  const {
    label,
    originalLabel = 'Original image',
    resource,
    source,
    create = false
  } = props;
  if (create) {
    return (
      <RAImageInput label={label} source={source} resource={resource}>
        <ImageField source="src" />
      </RAImageInput>
    );
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item>
          <RAImageInput label={label} source={source} resource={resource}>
            <ImageField source="src" />
          </RAImageInput>
        </Grid>
        <Grid item>
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              if (!formData?.[source]?.src) {
                return (
                  <div>
                    <Labeled label={originalLabel}>
                      <ImageField source={source} {...rest} />
                    </Labeled>
                  </div>
                );
              }
            }}
          </FormDataConsumer>
        </Grid>
      </Grid>
    </Box>
  );
};

ImageInput.propTypes = {
  label: PropTypes.string,
  originalLabel: PropTypes.string,
  resource: PropTypes.string,
  source: PropTypes.string,
  create: PropTypes.bool
};
