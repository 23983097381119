import {
  Create,
  Datagrid,
  DateField,
  Edit,
  ImageField,
  List,
  NumberField,
  NumberInput,
  Resource,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput
} from 'react-admin';
import { FilterSidebar } from '../components/FilterSidebar';
import { ImageInput } from '../components/ImageInput';
import gql from 'graphql-tag';

const name = 'Equipment';
export const fragments = {
  type: 'document',
  doc: gql`
    fragment FragEquipment on findManyEquipment {
      id
      duration
      iconUrl
      imageUrl
      name
      title
      videoUrl
      createdAt
      updatedAt
      _count {
        exercises
        users
        userProfiles
        workouts
        __typename
      }
      __typename
    }
  `
};

const list = () => (
  <List aside={<FilterSidebar />} sort={{ field: 'name', order: 'ASC' }}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="title" />
      <ImageField source="iconUrl" />
      <ImageField source="imageUrl" />
      <TextField source="videoUrl" />
      <NumberField source="duration" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);

const show = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="title" />
      <ImageField source="iconUrl" />
      <ImageField source="imageUrl" />
      <TextField source="videoUrl" />
      <NumberField source="duration" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </SimpleShowLayout>
  </Show>
);

const edit = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm>
      <TextField source="id" />
      <TextInput source="name" />
      <TextInput source="title" />
      <ImageInput source="iconUrl" />
      <ImageInput source="imageUrl" />
      <TextInput source="videoUrl" />
      <NumberInput source="duration" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </SimpleForm>
  </Edit>
);

const create = () => (
  <Create>
    <SimpleForm>
      <TextField source="id" />
      <TextInput source="name" />
      <TextInput source="title" />
      <ImageInput source="iconUrl" create />
      <ImageInput source="imageUrl" create />
      <TextInput source="videoUrl" />
      <NumberInput source="duration" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </SimpleForm>
  </Create>
);

export default function AdminResource(props) {
  return (
    <Resource
      create={create}
      edit={edit}
      list={list}
      name={name}
      show={show}
      {...props}
    />
  );
}
