import { Datagrid, List, Resource, TextField } from 'react-admin';
import gql from 'graphql-tag';

export const name = 'BusinessMetricUserSummary';

export const fragments = {
  type: 'document',
  doc: gql`
    fragment FragBusinessMetricUserSummaries on businessMetricUserSummaries {
      id
      date
      users
      __typename
    }
  `
};

const list = () => (
  <List>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="date" />
      <TextField source="users" />
    </Datagrid>
  </List>
);

export default function AdminResource(props) {
  return <Resource list={list} name={name} {...props} />;
}
