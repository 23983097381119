import { memo } from 'react';
import { UrlField } from 'react-admin';

import PropTypes from 'prop-types';

export const VideoField = memo(props => {
  const { source } = props;
  return <UrlField source={source} />;
});

VideoField.propTypes = {
  source: PropTypes.string.isRequired
};

VideoField.displayName = 'VideoField';
