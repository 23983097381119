import {
  ArrayInput,
  AutocompleteInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  List,
  ReferenceField,
  ReferenceInput,
  Resource,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput
} from 'react-admin';
import { ProgramScheduleFilterSidebar } from '../components/FilterSidebar';
import { SimpleFormIteratorInput } from '../components/SimpleFormIteratorInput';
import gql from 'graphql-tag';

export const name = 'ProgramSchedule';
export const fragments = {
  type: 'document',
  doc: gql`
    fragment FragProgramSchedules on programSchedules {
      id
      programId
      description
      name
      createdAt
      updatedAt
      _count {
        workouts
        __typename
      }
      program {
        id
        name
        __typename
      }
      workouts(orderBy: { order: asc }) {
        id
        name
        order
        workout {
          id
          name
          setup
          equipments {
            id
            name
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  `
};

const list = () => (
  <List
    aside={<ProgramScheduleFilterSidebar />}
    sort={{ field: 'name', order: 'ASC' }}
  >
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="program.name" />
      <TextField source="name" />
      <TextField source="description" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);

const show = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField source="program" reference="Program">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" />
      <TextField source="description" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </SimpleShowLayout>
  </Show>
);

const edit = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm>
      <TextField source="id" />
      <ReferenceInput source="program_id" reference="Program">
        <AutocompleteInput
          optionText="name"
          optionValue="id"
          fullWidth
          filterToQuery={searchText => ({ name: searchText })}
        />
      </ReferenceInput>
      <TextInput source="name" fullWidth />
      <TextInput source="description" fullWidth multiline rows={4} />
      <ArrayInput
        source="workouts"
        resource="WorkoutProgramSchedule"
        sort={{ field: 'order', order: 'ASC' }}
      >
        <SimpleFormIteratorInput inline orderSource="order">
          <ReferenceInput source="workout.id" reference="Workout">
            <AutocompleteInput
              optionText={record => {
                let name = `${record.name} - ${record.setup.replace(/(^|\s)\S/g, t => t.toUpperCase())}`;
                if (record.equipments.length > 0) {
                  name += ` - ${record.equipments.map(e => e.name).join(', ')}`;
                }
                return name;
              }}
              optionValue="id"
              fullWidth
              filterToQuery={searchText => ({ name: searchText })}
            />
          </ReferenceInput>
          <TextInput label="Program Workout Name" source="name" fullWidth />
        </SimpleFormIteratorInput>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);

const create = () => (
  <Create>
    <SimpleForm>
      <TextField source="id" />
      <ReferenceInput source="program_id" reference="Program">
        <AutocompleteInput
          optionText="name"
          optionValue="id"
          fullWidth
          filterToQuery={searchText => ({ name: searchText })}
        />
      </ReferenceInput>
      <TextInput source="name" />
      <TextInput source="description" multiline rows={4} fullWidth />
      <ArrayInput source="workouts" resource="WorkoutProgramSchedule">
        <SimpleFormIteratorInput inline orderSource="order">
          <ReferenceInput source="workout.id" reference="Workout">
            <AutocompleteInput
              optionText={record => {
                let name = `${record.name} - ${record.setup.replace(/(^|\s)\S/g, t => t.toUpperCase())}`;
                if (record.equipments.length > 0) {
                  name += ` - ${record.equipments.map(e => e.name).join(', ')}`;
                }
                return name;
              }}
              optionValue="id"
              filterToQuery={searchText => ({ name: searchText })}
              fullWidth
            />
          </ReferenceInput>
          <TextInput
            label="Program Workout Name"
            source="name"
            sx={{ width: 400 }}
          />
        </SimpleFormIteratorInput>
      </ArrayInput>
    </SimpleForm>
  </Create>
);

export default function AdminResource(props) {
  return (
    <Resource
      create={create}
      edit={edit}
      list={list}
      name={name}
      show={show}
      {...props}
    />
  );
}
