import PropTypes from 'prop-types';
import { ResponsiveSunburst } from '@nivo/sunburst';
import { useState } from 'react';
import { useTheme } from 'react-admin';
import { Canvg } from 'canvg';
import { saveAs } from 'file-saver';
import { useRef } from 'react';
import { Button, ButtonGroup } from '@mui/material';
import { valueFormatter } from '../helpers/graphs';

const removeChildren = data => {
  const childrenRemoved = Object.assign({}, data);
  childrenRemoved.children = [...childrenRemoved.children].map(child => {
    const newChild = Object.assign({}, child);
    delete newChild.children;
    return newChild;
  });
  return childrenRemoved;
};

const wordWrap = (text, maxWidth) => {
  const words = text.split(' ');
  const lines = [];
  let currentLine = words[0];

  for (let i = 1; i < words.length; i++) {
    const testLine = `${currentLine} ${words[i]}`;
    const width = testLine.length * 6; // Approximate width using 6px per character

    if (width > maxWidth) {
      lines.push(currentLine);
      currentLine = words[i];
    } else {
      currentLine = testLine;
    }
  }

  lines.push(currentLine);
  return lines;
};

export const SunburstGraph = ({ data, id, name, ...rest }) => {
  const [chartData, setChartData] = useState(removeChildren(data));
  const theme = useTheme();
  const isDarkMode = (theme?.[0]?.palette?.mode || theme?.[0]) === 'dark';
  const chartRef = useRef(null);
  const tooltipBackground = isDarkMode ? 'black' : 'white';
  const tooltipTextColor = isDarkMode ? 'white' : 'black';

  const downloadSVGAsPNG = () => {
    if (chartRef.current) {
      const svg = chartRef.current.querySelector('svg');
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const v = Canvg.fromString(ctx, svg.outerHTML);
      v.render().then(() => {
        canvas.toBlob(blob => {
          saveAs(blob, 'chart.png');
        });
      });
    }
  };

  const downloadSVG = () => {
    if (chartRef.current) {
      const svg = chartRef.current.querySelector('svg');
      const serializer = new XMLSerializer();
      const svgBlob = new Blob([serializer.serializeToString(svg)], {
        type: 'image/svg+xml'
      });
      saveAs(svgBlob, 'chart.svg');
    }
  };

  const flattenData = node => {
    const result = [];

    const recursiveFlatten = (node, path) => {
      result.push({
        path: [...path, node.id].join(' > '),
        value: node.value
      });
      if (node.children) {
        node.children.forEach(child => {
          recursiveFlatten(child, [...path, node.id]);
        });
      }
    };

    // Start the recursion from the children of the root node
    if (node.children) {
      node.children.forEach(child => {
        recursiveFlatten(child, []);
      });
    }

    return result;
  };

  const downloadDataAsCSV = () => {
    const flatData = flattenData(data);

    let csv = 'Path,Value\n';
    flatData.forEach(item => {
      csv += `"${item.path}",${item.value}\n`;
    });
    const blob = new Blob([csv], { type: 'text/csv' });
    saveAs(blob, 'data.csv');
  };

  const labelLayer = ({ nodes, centerX, centerY }) => {
    return nodes.map((node, index) => {
      if (node.percentage < 1.5) {
        return;
      }

      const angle = (node.arc.startAngle + node.arc.endAngle) / 2;

      // Start of the line (at the edge of the sunburst area)
      const startX =
        Math.cos(angle - Math.PI / 2) * node.arc.outerRadius + centerX;
      const startY =
        Math.sin(angle - Math.PI / 2) * node.arc.outerRadius + centerY;

      // End of the line (extending 10px outward from the sunburst)
      const endX =
        Math.cos(angle - Math.PI / 2) * (node.arc.outerRadius + 10) + centerX;
      const endY =
        Math.sin(angle - Math.PI / 2) * (node.arc.outerRadius + 10) + centerY;

      // Calculate rotation angle for the label
      let angleInDegrees = (angle * 180) / Math.PI - 90;

      let textAnchor = 'start';

      if (angleInDegrees > 90 && angleInDegrees <= 270) {
        angleInDegrees += 180;
        textAnchor = 'end';
      }

      const labelLines = wordWrap(node.id, 50);
      const tsHeight = 14;
      const textPadding = 5;
      const textX = endX + Math.cos(angle - Math.PI / 2) * textPadding;
      const textY = endY + Math.sin(angle - Math.PI / 2) * textPadding;
      const valueStartX =
        Math.cos(angle - Math.PI / 2) * node.arc.innerRadius + centerX;
      const valueStartY =
        Math.sin(angle - Math.PI / 2) * node.arc.innerRadius + centerY;
      const valueEndX =
        Math.cos(angle - Math.PI / 2) * (node.arc.innerRadius - 10) + centerX;
      const valueEndY =
        Math.sin(angle - Math.PI / 2) * (node.arc.innerRadius - 10) + centerY;
      const valueTextX =
        valueEndX + Math.cos(angle - Math.PI / 2) * (-1 * textPadding);
      const valueTextY =
        valueEndY + Math.sin(angle - Math.PI / 2) * (-1 * textPadding);
      const valueTextAnchor = textAnchor === 'start' ? 'end' : 'start';

      return (
        <g key={index}>
          <line
            x1={startX}
            y1={startY}
            x2={endX}
            y2={endY}
            stroke={isDarkMode ? 'white' : 'black'}
            strokeWidth="1"
          />
          <text
            x={textX}
            y={textY - ((labelLines.length - 1) * tsHeight) / 2}
            dy=".35em"
            textAnchor={textAnchor}
            transform={`rotate(${angleInDegrees}, ${textX}, ${textY})`}
            fill={isDarkMode ? 'white' : 'black'}
          >
            {labelLines.map((line, li) => (
              <tspan x={textX} dy={li ? tsHeight : 0} key={li}>
                {line}
              </tspan>
            ))}
          </text>
          <line
            x1={valueStartX}
            y1={valueStartY}
            x2={valueEndX}
            y2={valueEndY}
            stroke={isDarkMode ? 'white' : 'black'}
            strokeWidth="1"
          />
          <text
            x={valueTextX}
            y={valueTextY}
            dy=".35em"
            textAnchor={valueTextAnchor}
            transform={`rotate(${angleInDegrees}, ${valueTextX}, ${valueTextY})`}
            fill={isDarkMode ? 'white' : 'black'}
          >
            {valueFormatter(node.value)}
          </text>
        </g>
      );
    });
  };

  return (
    <div
      style={{ textAlign: 'center', width: '100%', height: '100%' }}
      ref={chartRef}
    >
      <ResponsiveSunburst
        data={chartData}
        animate
        arcLabelsRadiusOffset={1.5}
        arcLabelsSkipAngle={4}
        arcLabelsTextColor={{
          from: { scheme: 'nivo' },
          modifiers: [['darker', 5]]
        }}
        layers={['arcs', labelLayer]}
        borderWidth={1}
        colors={{ scheme: 'nivo' }}
        enableArcLabels
        motionConfig="gentle"
        transitionMode="pushIn"
        onClick={clickedData => {
          let foundObject = data;
          const paths = clickedData.path.reverse();
          paths.shift(); // the first path is the root node, so we remove it
          for (const path of paths) {
            foundObject = foundObject?.children?.find(fo => fo.id === path);
            if (!foundObject) {
              break;
            }
          }

          if (foundObject && foundObject?.children?.length) {
            setChartData(
              removeChildren({
                id: foundObject.id,
                children: foundObject.children
              })
            );
          }
        }}
        {...rest}
        theme={{
          tooltip: {
            container: {
              background: tooltipBackground,
              color: tooltipTextColor
            }
          }
        }}
      />
      <ButtonGroup variant="contained" style={{ marginTop: '10px' }}>
        <Button onClick={downloadSVGAsPNG}>Download PNG</Button>
        <Button onClick={downloadSVG}>Download SVG</Button>
        <Button onClick={downloadDataAsCSV}>Download CSV</Button>
      </ButtonGroup>
    </div>
  );
};

SunburstGraph.propTypes = {
  data: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  ...ResponsiveSunburst.propTypes
};
