import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './App';
import { RecoilRoot } from 'recoil';
import RecoilNexus from 'recoil-nexus';

Sentry.init({
  dsn: 'https://130114147e845838862fdae18b39bc71@o4507017555148800.ingest.us.sentry.io/4507376806395904',
  integrations: [new BrowserTracing()],
  tracesSampleRate:
    process.env.REACT_APP_API === 'https://api.litmethod.com' ? 0.5 : 0
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RecoilRoot>
      <RecoilNexus />
      <App />
    </RecoilRoot>
  </React.StrictMode>
);
