import PropTypes from 'prop-types';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import { useInput, FieldTitle } from 'ra-core';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { sanitizeInputRestProps, InputHelperText } from 'react-admin';

/**
 * An Input component for a string
 *
 * @example
 * <SliderInput source="first_name" />
 *
 * You can customize the `type` props (which defaults to "text").
 * Note that, due to a React bug, you should use `<NumberField>` instead of using type="number".
 * @example
 * <SliderInput source="email" type="email" />
 * <NumberInput source="nb_views" />
 *
 * The object passed as `options` props is passed to the <ResettableTextField> component
 */
export const SliderInput = props => {
  const {
    className,
    defaultValue = 0,
    label,
    format,
    helperText,
    onBlur,
    onChange,
    parse,
    step = 5,
    max = 100,
    min = 0,
    margin = 'dense',
    marks = true,
    valueLabelDisplay = 'auto',
    resource,
    source,
    tooltipFormatter = v => v,
    validate,
    ...rest
  } = props;
  const {
    field,
    fieldState: { error, invalid, isTouched },
    formState: { isSubmitted },
    id,
    isRequired
  } = useInput({
    defaultValue,
    format,
    parse,
    resource,
    source,
    type: 'text',
    validate,
    onBlur,
    onChange,
    ...rest
  });

  return (
    <Box>
      <StyledFormControl
        component="fieldset"
        margin={margin}
        error={(isTouched || isSubmitted) && invalid}
        className={clsx('ra-input', `ra-input-${source}`, className)}
        {...sanitizeInputRestProps(rest)}
      >
        <FormLabel component="legend" className={SliderInput.label}>
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        </FormLabel>
        <Slider
          aria-label={label}
          id={id}
          {...field}
          defaultValue={defaultValue}
          getAriaValueText={tooltipFormatter}
          valueLabelDisplay={valueLabelDisplay}
          marks={marks}
          step={step}
          min={min}
          max={max}
          className={clsx('ra-input', `ra-input-${source}`, className)}
          {...sanitizeInputRestProps(rest)}
        />
        {(isTouched || isSubmitted) && invalid ? (
          <FormHelperText>
            <InputHelperText
              touched={isTouched || isSubmitted}
              error={error?.message}
              helperText={helperText}
            />
          </FormHelperText>
        ) : null}
      </StyledFormControl>
    </Box>
  );
};

SliderInput.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.any,
  format: PropTypes.func,
  helperText: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  margin: PropTypes.oneOf(['none', 'dense', 'normal']),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  parse: PropTypes.func,
  options: PropTypes.object,
  tooltipFormatter: PropTypes.func,
  resource: PropTypes.string,
  source: PropTypes.string,
  step: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number,
  marks: PropTypes.bool,
  valueLabelDisplay: PropTypes.string,
  validate: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.func)
  ])
};

SliderInput.defaultProps = {
  defaultValue: 0,
  step: 5,
  max: 100,
  min: 0,
  margin: 'dense',
  marks: true,
  valueLabelDisplay: 'auto'
};

const PREFIX = 'SlSliderInput';

export const SliderInputClasses = {
  label: `${PREFIX}-label`
};

const StyledFormControl = styled(FormControl, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root
})(({ theme }) => ({
  [`& .${SliderInputClasses.label}`]: {
    transform: 'translate(0, 8px) scale(0.75)',
    transformOrigin: `top ${theme.direction === 'ltr' ? 'left' : 'right'}`
  }
}));
